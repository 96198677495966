.teacher{

  .b-listItem{
    // display: flex;
    // align-items: center;
  }

  &__employ{
    display: flex;
    margin-top: 32px;
    margin-bottom: 84px;
  }

  &__description{
    flex: 1;
    margin-left: 64px;
  }

  &__position{
    @include text-style-others-s-22-w-500;
    margin-top: 16px;
  }
  
  &__block {

    &-title {
      margin-bottom: 18px;
    }

    &-info {
      margin-top: 40px;
    }

    &-text {

      &_bold {
        font-weight: 700;
      }
    }
  }

  &__qualification {
    max-width: 1336px;
    margin-bottom: 140px;
  }

  &__cards {
    margin-bottom: 140px;

    .m-courses {

      &__button {
        margin-top: 42px;
      }
    }
  }

  &__img{
    flex: 1;
    width: 100%;
    max-width: 462px;
    height: 100%;
    overflow: hidden;
    border-radius: 34px;

    img{
      object-fit: cover;
    }
  }
}