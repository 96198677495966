.privacy-policy{
  margin: 24px 0 128px;

  p {
    @include text-style-texts-s-18-w-400;
    margin-bottom: 10px;
    color: $color-achromatic-black;
  }

  ul {
    @include text-style-texts-s-18-w-400;

    li {
      display: flex;
      margin-bottom: 12px;
      
      &::before {
        content: url('../assets/images/decorations/dot-black.svg');
        width: 5px;
        height: 5px;
        margin-right: 12px;
      }
    }
  }
}