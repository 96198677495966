.success-reg-info {
  padding: 50px 0;

  p {
    @include text-style-others-s-20-w-400;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  &__title {
    @include text-style-titles-h-1-s-48;
    margin-bottom: 20px;
    text-align: center;
    color: $color-primary-500;
  }

  &__text {
    &_important {
      font-weight: 600;
    }

    &_required {
      color: $color-secondary-red-500;
    }
  }

  &__list {

    li {
      @include text-style-others-s-18-w-400;

      &.b-listItem_required {
        &::before {
          content: '*';
          color: $color-secondary-red-500;
        }
      }

    }
  }

  &__download-link {
    @include text-style-others-s-22-w-600;
    display: block;
    margin-top: 20px;
    text-transform: uppercase;
    text-align: center;
    color: $color-secondary-red-400;
    transition: 0.3s;

    &:hover {
      color: $color-secondary-red-500;
      text-decoration: underline;
    }
  }
}