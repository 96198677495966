/* ================================ Combinations ================================ */

.c- {

  &courseType {

    flex: 1;
    width: 100%;
    margin-right: 24px;
    padding: 46px 46px 34px 44px;
    color: $color-achromatic-black;
    background-color: $color-achromatic-white;
    border-radius: 16px;
    cursor: pointer;
    transition: box-shadow 0.3s;
    @include effect-style-shadows-blur-100;


    &__title {
      max-width: 366px;
      margin-bottom: 8px;
    }

    &:hover {
      @include effect-style-shadows-blur-50;
    }

    &:last-child{
      margin-right: 0;
    }

    &__img {

      svg {
        width: 100px;
        height: 100px;
      }
      margin-bottom: 32px;
    }

    &__text {
      @include text-style-texts-s-18-w-400;
    }

  }

  &profession {
    position: relative;
    // width: 360px;
    height: 360px;
    color: $color-achromatic-white;
    border-radius: 16px;
    cursor: pointer;

    a{
      color: $color-achromatic-white;
    }

    &__blackOut {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      background: $color-achromatic-black;
      opacity: 0;
      transition-duration: 0.3s;
    }

    &:hover {

      .c-profession__blackOut {
        opacity: 0.2;
      }
    }

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__container {
      position: absolute;
      bottom: 30px;
      left: 36px;
      z-index: 2;
    }

    &__title {
      @include text-style-others-s-22-w-600;
      margin-bottom: 8px;
    }

    &__text {
      @include text-style-others-s-18-w-400;
    }

    &__gradient{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 278px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.47) 53.65%, #000000 100%);
      opacity: 0.72;
    }
  }

  &teacherCard {
    overflow: hidden;

    color: $color-achromatic-black;
    border-radius: 16px;
    cursor: pointer;
    @include effect-style-shadows-blur-100;

    &__img {
      max-height: 320px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__container {
      padding: 18px 24px 64px;
      background-color: $color-achromatic-white;
    }

    &__text {
      @include text-style-others-s-16-w-400;
      margin-top: 12px;
    }


  }

  &titleHead{
    margin-bottom: 42px;

    &__title {
      margin-bottom: 16px;
    }

    &__container{
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    &__text{
      @include text-style-texts-s-22-w-400;
      flex: 1;
    }

    &__link{
      @include text-style-others-s-18-w-400;
      white-space: nowrap;
    }
  }

  &subdivision{
    margin-bottom: 28px;

    &__title {
      margin-bottom: 10px;
    }

    &__text{
      @include text-style-texts-s-18-w-400;
      margin-bottom: 16px;
    }

    &__link{

      a {
        @include text-style-texts-s-18-w-400;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &qualification{
    display: flex;
    height: 100%;
    min-height: 100px;

    &:last-child {
      height: auto;

      .c-qualification__info {
        border: none;
      }
    }

    &__date {
      width: 27%;

      &-mb{
        @include text-style-titles-h-6-s-18;
        display: none;
        margin-bottom: 8px;
        color: $color-primary-500;
      }
    }

    &__info {
      position: relative;
      width: 73%;
      padding-bottom: 65px;
      padding-left: 88px;
      border-left: 3px solid $color-primary-500;
    }

    &__icon {
      position: absolute;
      top: 0;
      left: -35px;
      width: 70px;
      height: 70px;
      background-color: $color-primary-500;
      border-radius: 100%;
    }

    &__description {
      margin-top: 14px;
      @include text-style-texts-s-22-w-400;
    }

    .--arrows {
      background-image: url('../assets/images/decorations/arrows-up-filled.svg');
      background-repeat: no-repeat;
      background-position: center;
    }

    .--sync {
      background-image: url('../assets/images/decorations/sync-filled.svg');
      background-repeat: no-repeat;
      background-position: center;
    }

    &:last-child{

      .c-qualification__info{
        padding-bottom: 0;
      }
    }
  }

  &breadcrumbs {
    margin-bottom: 12px;

    ul {
      display: flex;
      flex-wrap: wrap;
    }

    a {
      @include text-style-others-s-16-w-400;
      display: inline-block;
      max-width: 200px;
      overflow: hidden;
      color: $color-achromatic-black;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__item {
      position: relative;
      display: inline;
      margin-left: 21px;


      &:first-child {
        margin-left: 0px;
      }

      &:not(:first-child)::before {
        position: absolute;
        top: 9px;
        left: -12px;
        content: '';
        width: 4px;
        height: 4px;
        background-color: $color-achromatic-black;
        border-radius: 100%;
      }

      &:last-child {

        a {
          color: $color-achromatic-gray-500;
          user-select: none;
          pointer-events: none;
        }
      }
    }
  }

  &module{
    position: relative;
    display: flex;
    max-width: 1305px;
    padding-bottom: 74px;
    border-left: 3px solid $color-primary-500;

    &::before {
      position: absolute;
      top: -3px;
      left: -20px;
      content: '';
      width: 36px;
      height: 36px;
      background-color: $color-primary-500;
      border-radius: 100%;
    }

    &:last-child{
      margin-left: 3px;
      padding-bottom: 0px;
      border: none;
    }

    &__name{
      width: 100%;
      max-width: 619px;
      padding-left: 91px;

      &-title {
        margin-bottom: 8px;
        color: $color-primary-500;
      }

      &-subtitle {
        @include text-style-titles-h-3-s-30;
      }
    }

    &__lessons{
      margin-left: 72px;

      &-title{
        margin-bottom: 16px;
        color: $color-primary-500;
      }

      &-list {

        .b-listItem {
          display: flex;
        }
      }
    }
  }
}


.c-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 23px;
  overflow: hidden;
  // height: 100%;
  color: $color-achromatic-black;
  background-color: $color-achromatic-white;
  border-radius: 16px;
  box-shadow: 0px 0px 36px rgba(0, 0, 0, 0.08);
  transition: box-shadow 0.3s;

  a{
    color: $color-achromatic-black;
  }

  &:last-child{
    margin-right: 0;
  }

  &:hover {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
  }

  &__img {
    max-height: 304px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__img-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    padding: 0 2% 2%;

    position: absolute;
    left: 0;
    bottom: 0;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    z-index: 1;
  }

  &__title{
    @include text-style-others-s-22-w-600;
    cursor: pointer;
  }

  @media screen and (max-width: 575px) {
    &__title {
      font-size: 16px;
    }
  }

  &__info {
    margin-top: 22px;
  }

  &__price{
    @include text-style-others-s-20-w-500;
    margin-top: 24px;
    color: $color-secondary-red-400;

    &.money{
      color: $color-secondary-blue-500;

      +.money{
        margin-top: 0px;
        font-size: smaller;
      }
    }
  }

  &__time{
    @include text-style-others-s-18-w-400;
    margin-top: 10px;
  }

  .b-status{
    @include text-style-others-s-16-w-400;
  }

  &__tags{
    @include text-style-others-s-16-w-400;
    z-index: 1;

      a{
      margin-right: 20px;
      color: $color-primary-500;

      &:last-child{
        margin-right: 0;
      }
    }
  }

  &__tag {
    cursor: pointer;
  }
}
