/* ================================ Base ================================ */

.b- {

  &link {
    @include text-style-others-s-14-w-400;
    transition: color 0.2s;

    a {
      color: $color-achromatic-black;
      transition: color 0.2s;

      &:hover {
        color: $color-primary-500;
      }
    }

    &.--green {
      color: $color-primary-500;

      a {
        color: $color-primary-500;
      }

      &:hover {
        color: $color-secondary-blue-300;
      }
    }

    &.--white {

      a {
        color: $color-achromatic-white;

        &:hover {
          color: $color-secondary-blue-300;
        }
      }
    }

    &.--disabled {
      color: $color-achromatic-gray-500;
    }
  }

  &button{
    @include text-style-others-s-18-w-500;
    padding: 16px 44px;
    color: $color-primary-500;
    border: 1px solid $color-primary-500;
    border-radius: 56px;
    cursor: pointer;
    transition-duration: 0.3s;

    a {
      color: $color-primary-500;
      transition-duration: 0.3s;
    }

    &:hover {
      color: $color-achromatic-white;
      background-color: $color-primary-500;

      a {
        color: $color-achromatic-white;
      }
    }

    &:disabled,
    &.--disabled {
      color: $color-achromatic-gray-500;
      background-color: $color-achromatic-white;
      border-color: $color-achromatic-gray-500;
      cursor: no-drop;

      a {
        color: $color-achromatic-gray-500;
        cursor: no-drop;
      }
    }

    &.--long {
      width: 100%;
      max-width: 342px;
    }

    @media screen and (min-width: 320px) and (max-width: 575px) {
      &.--filled {
        width: 100%;
      }
    }

    &.--filled {
      color: $color-achromatic-white;
      background-color: $color-primary-500;

      a {
        color: $color-achromatic-white;
      }

      &:hover {
        background-color: $color-secondary-blue-300;
        border: 1px solid $color-secondary-blue-300;
      }
    }

    &.--disabled.--filled,
    &:disabled.--filled {
      color: $color-achromatic-white;
      background-color: $color-primary-500;
      opacity: 0.5;
      cursor: no-drop;

      a {
        color: $color-achromatic-white;
        cursor: no-drop;
      }
    }

    &-icon {
    }
  }

  &iconButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 54px;
    height: 54px;
    background-color: transparent;
    border-radius: 100%;
    cursor: pointer;
    transition-duration: 0.3s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    svg {
      width: 38px;
      height: 38px;

      path {
        fill: $color-achromatic-black;
      }
    }

    &.--green {

      svg path {
        fill: $color-primary-500;
      }

      &:hover {
        background-color: rgba(33, 138, 61, 0.05);
      }
    }

    &.--white {

      svg path {
        fill: $color-achromatic-white;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }

  &input {
    position: relative;
    display: inline-block;

    &__error-text {
      @include text-style-others-s-12-w-400;
      position: absolute;
      top: 5px;
      right: 0;
      height: 0px;
      color: $color-secondary-red-400;
      opacity: 0;
      font-size: 13px;
      transition: opacity 0.3s;

      &.--active {
        height: auto;
        opacity: 1;
      }
    }

    .--correct {
      padding-bottom: 13px;
      border-bottom: 2px solid $color-primary-500;
    }

    .--error {
      padding-bottom: 13px;
      border-bottom: 2px solid $color-secondary-red-400;
    }
  }

  &toggle {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;

    &.--checked{
      
      input:checked + .b-toggle__slider {
        background-color: $color-primary-500;
        border: 2px solid $color-primary-500;
      }
  
      input:checked + .b-toggle__slider::before {
        background-color: $color-achromatic-white;
        transform: translateX(20px);
        transform: translateX(20px);
        transform: translateX(20px);
      }
    }

    input {
      width: 0;
      height: 0;
      opacity: 0;
    }

    &__slider {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: $color-achromatic-white;
      border: 2px solid $color-primary-200;
      cursor: pointer;
      transition: 0.4s;

      &::before {
        position: absolute;
        bottom: 0px;
        left: 0px;
        content: '';
        width: 20px;
        height: 20px;
        background-color: $color-primary-500;
        transition: 0.4s;
      }

      &.round {
        border-radius: 34px;
      }

      &.round::before {
        border-radius: 50%;
      }
    }
  }

  &cardButton {
    position: relative;
    max-width: 480px;
    height: 100%;
    padding: 58px 47px 73px 50px;
    background: $color-achromatic-white;
    border-radius: 16px;
    cursor: pointer;
    transition: box-shadow 0.3s;
    @include effect-style-shadows-blur-100;

    &:hover {
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
    }

    svg {
      position: absolute;
      right: 47px;
      bottom: 73px;
      width: 132px;
      height: 132px;
      transform: rotate(90deg);

      path {
        fill: $color-primary-500;
      }
    }
  }

  &scrollTopButton {
    position: absolute;
    top: 0;
    right: 0;
    // display: none;
    width: 44px;
    height: 88px;
    background-color: $color-primary-100;
    border-radius: 10px 0px 0px 10px;
    opacity: 0;
    transition-duration: 0.3s;

    svg {
      width: 40px;
      height: 40px;

      path {
        fill: $color-primary-500;
      }
    }

    &:hover {
      background-color: $color-primary-500;

      svg {

        path {
          fill: $color-achromatic-white;
        }
      }
    }

    &.--active {
      // display: block;
      opacity: 1;
    }
  }

  &checkBox {
    @include text-style-others-s-18-w-400;

    &__container {
      position: relative;
      display: block;
      padding-left: 32px;
      cursor: pointer;
      font-size: 22px;
      user-select: none;

      span {
        @include text-style-others-s-18-w-400;
        color: black;
      }
      span a {
        color: #27832F;
      }

      input {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;
        cursor: pointer;
      }
    }

    &__checkmark {
      position: absolute;
      top: 0;
      left: 0;
      width: 25px;
      height: 25px;
      background-color: transparent;
      border: 2px solid $color-primary-500;
      border-radius: 4px;
    }

    &.--checked {

      .b-checkBox__checkmark::after {
        position: absolute;
        top: -2px;
        left: -1px;
        z-index: 1;
        display: none;
        content: url('../assets/images/actions/check-white.svg');
      }

      .b-checkBox__container {

        &:hover input ~ .b-checkBox__checkmark {
          background-color: transparent;
        }

        & input:checked ~ .b-checkBox__checkmark {
          background-color: $color-primary-500;

          &::after {
            display: block;
          }
        }
      }
    }
  }

  &iconDot {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    background-color: $color-primary-500;
    border: 1px solid $color-primary-500;
    border-radius: 51px;

    svg {
      width: 34px;
      height: 34px;
    }
  }

  &socialIcon {
    cursor: pointer;
    transition-duration: 0.3s;

    svg {
      width: 24px;
      height: 24px;

      path {
        fill: $color-achromatic-white;
        transition-duration: 0.3s;
      }
    }

    &:hover {

      svg path {
        fill: $color-secondary-blue-300;
      }
    }

    &.--green {

      svg path {
        fill: $color-primary-500;
        transition-duration: 0.3s;
      }

      &:hover {

        svg path {
          fill: $color-secondary-blue-350;
        }
      }
    }
  }

  &counter {

    &__number {
      @include text-style-titles-h-1-s-48;
      margin-bottom: 2px;
      color: $color-primary-500;
    }

    &__text {
      @include text-style-texts-s-24-w-400;
      color: $color-achromatic-gray-700;
    }
  }

  &status {
    @include text-style-others-s-22-w-400;
    white-space: nowrap;

    &__condition {
      display: flex;
      flex-wrap: wrap;

      .b-status {

        &__start {

          &::before {
            display: inline-block;
            content: '';
            width: 10px;
            height: 10px;
            margin-right: 10px;
            background-image: url('../assets/images/actions/open-course.svg');
            background-repeat: no-repeat;
            background-size: cover;
          }
        }

        &__close {

          &::before {
            display: inline-block;
            content: '';
            width: 10px;
            height: 10px;
            margin-right: 10px;
            background-image: url('../assets/images/actions/close-course.svg');
            background-repeat: no-repeat;
            background-size: cover;
          }
        }

        &__date {
          margin-left: 7px;

          &:first-child {
            margin-right: 0px;
          }

          &::before {
            content: '•';
            width: 10px;
            height: 10px;
            margin-right: 10px;
          }
        }
      }

      &.--online {
        // @include text-style-titles-h-5-s-20;
        color: $color-primary-500;
      }

      &.--offline {
        // @include text-style-titles-h-5-s-20;
        color: $color-secondary-red-400;
      }
    }
  }

  &listItem {
    @include text-style-texts-s-18-w-400;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0px;
    }

    &::before {
      content: url('../assets/images/decorations/dot-black.svg');
      width: 5px;
      height: 5px;
      margin-right: 12px;
    }
  }

  &faq {
    display: flex;

    &__img {

      svg {
        width: 48px;
        height: 48px;
      }
    }

    &__container {
      margin-left: 28px;
    }

    &__answer {
      @include text-style-texts-s-18-w-400;
      margin-top: 11px;
    }
  }

  &checkItem {
    display: flex;
    flex-direction: column;

    &__img {

      svg {
        width: 32px;
        height: 32px;

        path {
          fill: $color-primary-500;
        }
      }
    }

    &__text {
      margin-top: 28px;
      @include text-style-texts-s-18-w-400;
    }
  }

  &buttonsCategories {
    display: flex;
    flex-wrap: wrap;
    max-width: 1270px;
    margin-bottom: -16px;
    margin-left: -20px;

    & > * {
      margin-bottom: 16px;
      margin-left: 20px;
    }

    .b-button {
      @include text-style-others-s-16-w-500;
      padding: 12px 32px;
    }
  }

  &search {

    svg {
      width: 24px;
      height: 24px;

      path {
        fill: $color-primary-500;
        transition-duration: 0.3s;
      }
    }
  }
}
.pop-up__over {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999999;
  background-color: rgba(0, 0, 0, .3);
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: opacity .4s, visibility .4s;
}
.pop-up__over.open {
  visibility: visible;
  opacity: 1;
}
.pop-up {
  /*height: 782px;*/
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 44px 40px 40px;
  background: #FFFFFF;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.0224916), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0323114), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.04), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.0476886), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0575083), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
}
.pop-up__head {
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.pop-up__title {
  font-family: Montserrat;
  font-size: 30px;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.02em;
  text-decoration: none;
}
.pop-up__wrapper p {
  font-family: 'Bliss Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 125.4%;
  display: flex;
  align-items: flex-end;
  color: #000000;
}
.pop-up__wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6px 40px 0px 0px;
  gap: 28px;
}
.input-btn {
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 23px;
  gap: 8px;
  background: #27832F;
  border-radius: 10px;
  font-family: 'Bliss Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 37px;
  display: flex;
  align-items: flex-end;
  color: #FFFFFF;
}
.pop-up__wrapper input {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.02em;
  text-decoration: none;
  //border: 1px solid #b3b3b3;
  border-bottom: 2px solid green;
  border-radius: 0;
}
.pop-up__wrapper input[type="submit"] {
  border-radius: 33px;
}
.input-div {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: 20px;
}
.name-span {
  max-width: 280px;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.02em;
  text-decoration: none;
}
.name {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 13px 20px;
  gap: 8px;
  background: #F4F6F8;
  border-radius: 8px;
  margin-bottom: 16px;
  width: 441px;
}
.ph-num {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 20px;
  font-size: 22px;
  gap: 8px;
  width: 441px;
  height: 58px;
  background: #F4F6F8;
  border-radius: 8px;
  border: 0;
  margin-bottom: 16px;
  color: #818181;
}
textarea {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 11px 20px;
  gap: 8px;
  width: 441px;
  height: 257px;
  background: #F4F6F8;
  border-radius: 8px;
  border: 0;
  margin-bottom: 36px;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.02em;
  text-decoration: none;
  border: 1px solid #b3b3b3;
}
.pop-up_checkbox {
  max-width: 620px;
  margin-bottom: 20px;
}
.pop-up_checkbox label {
  font-family: Montserrat;
}
.zacon {
  color: #27832F;
}

.doc__pop-up__over {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999999;
  background-color: rgba(0, 0, 0, .3);
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: opacity .4s, visibility .4s;
}
.doc__pop-up__over.open {
  visibility: visible;
  opacity: 1;
}
.pu-doc__img {
  cursor: pointer;
}
.doc-pu__head {
  position: absolute;
  top: 35px;
  left: -189px;
}

@media screen and (min-width: 320px) and (max-width: 575px) {

  .doc-pu__head {
    top: 117px;
    left: -7px;
  }
  .name {
    width: 100%;
    height: auto;
  }
  textarea {
    width: unset;
  }
  @media screen and (min-width: 320px) and (max-width: 575px) {
    .faqs {
      padding: 0 40px 0 0;
    }
    .plus {
      width: 0;
      margin-left: 10px;
    }
    .faq-img_title {
      align-items: flex-start;
    }
    .faq-title {
      font-size: 12px;
    }
    .faq-content {
      font-size: 12px;
    }
    .pop-up {
      width: calc(100% - 20px);
      height: calc(100% - 115px);
      overflow: auto;
      //padding: 17px;
      padding: 25px;
    }

    .pop-up__wrapper {
      gap: 18px;
      padding: 6px 10px 0px 0px;
    }

    .pop-up__title {
      line-height: 24px;
      font-size: 20px;
    }

    .pop-up__wrapper p {
      font-size: 18px;
    }

    .pop-up__form {
      width: 100%;
    }
    //.name {
    //  width: unset;
    //}
    //.pop-up .pop-up__wrapper input {
    //  width: 100%;
    //}

    .input-div {
      flex-direction: column;
    }

    .courses-btns {
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
    }
    .courses-btns {
      gap: 0;
    }
  }
}
