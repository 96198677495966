.about {

  &__subdivisions {
    margin-bottom: 40px;
  }

  p {
    @include text-style-texts-s-18-w-400;
    margin-bottom: 10px;
    color: $color-achromatic-black;
  }

  ul.faqs li {
    margin-bottom: 0px;
  }

  h1 {
    width: 100%;
    max-width: 1076px;
  }

  ul {
    @include text-style-texts-s-18-w-400;

    li {
      display: flex;
      margin-bottom: 12px;

      &::before {
        content: url('../assets/images/decorations/dot-black.svg');
        width: 5px;
        height: 5px;
        margin-right: 12px;
      }
    }
  }

  .b-link {
    @include text-style-others-s-18-w-400;
  }

  .priority2030 {
    margin-bottom: 20px;
  }

  &__content {
    margin-bottom: 48px;

    h2 {
      max-width: 925px;
      margin-bottom: 32px;
    }
  }

  &__subdivisions {

    h3 {
      margin: 40px 0 32px;
    }
  }

  &__dpo {

    h2 {
      margin: 48px 0 32px;
    }

    h3 {
      margin: 32px 0 18px;
    }
  }

  &__docs {
    margin: 124px 0 140px;

    h2 {
      margin-bottom: 48px;
    }

    &-container {
      display: flex;

      img {
        width: 24%;
        margin-right: 24px;
        border-radius: 27px;
        object-fit: cover;
        cursor: pointer;

        &:last-child {
          margin-right: 0px;
        }

      }
    }

  }

  &__title {
    text-transform: uppercase;
    color: #22863C;
    margin-bottom: 32px;
  }

  &__main {
    position: relative;
    overflow: hidden;
    margin-bottom: 120px;
    min-height: 400px;
    padding: 50px;
    border-radius: 27px;
    background-color: rgba(#22863C, 0.2);

    &-bg {
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      object-fit: cover;
    }

    &-content {
      position: relative;
      z-index: 3;
    }

    &-texts {
      position: relative;
      z-index: 1;
      max-width: 700px;
    }

    &-text {
      margin-top: 20px;
      font-family: Montserrat;
      font-size: 15px;
      font-weight: 600;
      line-height: 144%;
      letter-spacing: 0.02em;

      &-custom {
        color: #001f6e;

        & > span {
          color: #e8002d;
        }
      }
    }

    &-effect {
      opacity: 0.5;

      &::before {
        content: "";
        height: 100%;
        left: 0;
        opacity: 1;
        position: absolute;
        top: 0;
        transition: all .3s ease-in-out;
        visibility: visible;
        width: 100%;

        background-color: #a7cfb1;
        mix-blend-mode: soft-light;
        z-index: 1;
      }

      //&::after {
      //  content: "";
      //  height: 100%;
      //  left: 0;
      //  opacity: 1;
      //  position: absolute;
      //  top: 0;
      //  transition: all .3s ease-in-out;
      //  visibility: visible;
      //  width: 100%;
      //  background: #000;
      //  mix-blend-mode: hue;
      //  z-index: 0;
      //}
    }

    &-images {
      position: absolute;
      right: 10%;
      top: 0;
      z-index: 2;
      display: flex;
      height: 200%;
      flex-direction: column;
      animation: slideUp 2.5s linear infinite;

      img {
        width: 170px;
      }
    }

    &-img {
      height: 100%;
    }

    @keyframes slideUp {
      0% {
        transform: translateY(0%);
      }
      100% {
        transform: translateY(-50%);
      }

    }
  }

  &__stages {
    position: relative;
    margin-bottom: 120px;

    &-table {
      max-width: 900px;
      //margin: 0 auto;
      margin-left: auto;

      &-title {
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        align-items: center;
        text-transform: uppercase;
        color: #000000;
        font-family: Montserrat;
      }

      td {
        width: 50%;
        vertical-align: middle;
        padding: 0 20px;

        p {
          font-size: 20px;
          line-height: 23px;
          align-items: center;
          color: #000000;
          font-family: Montserrat;

          padding: 20px 0;
        }
      }

      tr {
        border-bottom: 1px solid #dddddd;
      }
    }

    &::before {
      content: '';
      z-index: -1;
      position: absolute;
      //left: -5%;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-image: url("/upload/logo-green.svg");
      background-repeat: no-repeat;
      background-size: 100%;
      width: 280px;
      height: 500px;
      opacity: 0.4;
    }
  }

  &__interacting {
    margin-bottom: 120px;

    &-title {
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      align-items: center;
      text-transform: uppercase;
      color: #000000;
      font-family: Montserrat;
      flex: 1 1 50%;
      display: flex;
      padding: 20px;
    }

    &-left {
      flex: 1 1 50%;
    }

    &-box {
      overflow: auto;
      width: 100%;
    }

    &-container {
      min-width: 400px;
    }

    &-right {
      flex: 1 1 50%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      padding: 15px 0px;

      align-items: center;

      img {
        max-width: 150px;
      }
    }

    &-item {
      display: block;
      padding: 20px;
      font-size: 20px;
      line-height: 23px;
      color: #000000;
      font-family: 'Montserrat';
    }

    &-content {
      display: flex;
      border-bottom: 1px solid #dddddd;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #dddddd;
    }

    &-table {
      max-width: 900px;
      margin: 0 auto;

      td {
        width: 50%;
        vertical-align: middle;
        padding: 40px 20px;

        p {
          font-size: 20px;
          line-height: 23px;
          align-items: center;
          color: #000000;
          font-family: Montserrat;
        }
      }

      tr {
        border-bottom: 1px solid #dddddd;
      }
    }
  }

  &__advantages {
    margin-bottom: 120px;

    &-container {
      display: grid;
      gap: 20px;
      grid-template-columns: repeat(4, 1fr);
    }

    &-item {
      display: flex;
      flex-direction: column;
      gap: 10px;

      height: 240px;
      padding: 30px;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      text-transform: uppercase;
      color: #484F5E;

      font-family: Montserrat;
      //box-shadow: 0 10px 0 #22863C;
      box-shadow: 0 10px 0 #d6f0dd;
      border: 1px solid #CCCCCC;
      border-bottom: 0;
      //background-image: linear-gradient(to bottom, #fff 50%, #22863C 50%);
      background-image: linear-gradient(to bottom, #fff 50%, #d6f0dd 50%);
      background-size: 100% 200%;
      transition: background-position .2s ease-in-out, color .2s ease-in-out;
      word-wrap: break-word;

      img {
        width: 45px;
        height: 45px;
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-position: 0 100%;
          //color: #fff;
          transition: background-position .2s ease-in-out, color .2s ease-in-out;
        }
      }
    }
  }

  @media screen and (max-width: 1240px) {

    &__advantages {
      &-container {
        gap: 20px;
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  @media screen and (max-width: 1024px) {
    &__stages {
      &::before {
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &-table {
        display: block;
        overflow-x: auto;

        &-title {
          font-size: 18px;
          line-height: 20px;
        }

        td {
          p {
            font-size: 14px;
            line-height: 16px;
          }
        }
      }
    }

    &__interacting {

      &-title {
        font-size: 18px;
        line-height: 20px;
      }


      &-table {
        display: block;
        overflow-x: auto;


        td {
          p {
            font-size: 14px;
            line-height: 16px;
          }
        }
      }

    }

    &__advantages {
      &-container {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__advantages {
      &-container {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
