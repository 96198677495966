.teachers{

  &.--active{

    .hidden{
      display: grid;
      height: 100%;
      margin-top: 32px;
      opacity: 1;
    }
  }

  &__title{
    margin-bottom: 88px;
  }

  &__cards{
    margin-top: 50px;
  }

  &__button{
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  &__feedback {
    margin: 140px 0;
  }

  &__accordion{
    display: none;

    li {
      a {
        color: $color-achromatic-black;
      }
    }
  }
}