.course{
  padding-top: 32px;

  &__block {
    margin-bottom: 48px;
  }

  &__about{
    margin-bottom: 116px;
  }

  &__checks{
    //margin-bottom: 140px;
    //margin-bottom: 100px;
    margin-bottom: 70px;
  }

  &__certificate {
    margin-bottom: 142px;
    padding-top: 82px;
  }

  @media screen and (min-width: 320px) and (max-width: 575px) {
    .course__modules {
      margin-top: 50px;
    }
  }

  &__modules{
    @include effect-style-shadows-blur-100;
    position: relative;
    //margin-bottom: 140px;
    //margin-bottom: 100px;
    margin-bottom: 70px;
    //padding: 96px 117px 187px 88px;
    padding: 30px 0px 46px 50px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: bottom left;
    border-radius: 27px;
    //margin-top: 140px;
    margin-top: 70px;

    &-bg {

      &-1 {

        &.bg-dots-1 {
          top: -110px;
          right: 20px;
        }
      }

      &-2 {

        &.bg-dots-3 {
          bottom: -380px;
          left: 30px;
          transform: rotate(180deg);
        }
      }
    }
  }

  .module {

    &__header {
      margin-bottom: 64px;
    }

    &__title {
      margin-bottom: 14px;
    }

    &__link {
      @include text-style-others-s-22-w-400;
    }
  }

  &__teachers {
    //margin-bottom: 140px;
    //margin-bottom: 100px;
    margin-bottom: 70px;
  }

  &__feedback{
    margin-bottom: 156px;
  }

  &__faqs{
    margin-bottom: 142px;
  }

  .course-info {
    display: flex;

    .statuses {
      width: 50%;

      .b-status {
        margin-bottom: 8px;
      }
    }

    &__rating {
      display: flex;
      align-items: center;

      &-text {
        color: $color-primary-500;
        padding-right: 5px;
        font-weight: bold;
      }
    }

    &__reviews-link {
      margin-left: 10px;
      z-index: 10;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .stars_container {
      display: inline-block;
      position: relative;
      width: 68px;
      height: 30px;
      background: #dadada;
      background-size: 100% 100%;

      .stars_bg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        background: url(../assets/images/img/stars.png) no-repeat center;
      }

      .stars_progress {
        width: 50%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        background: #e76110;
      }
    }

    &__title{
      margin-bottom: 40px;
    }

    &__img{
      position: relative;
      width: 100%;
      max-width: 596px;
      height: 100%;
      overflow: hidden;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.47) 53.65%, #000000 100%);
      border-radius: 34px;
      flex: 1;

      img{
        object-fit: cover;
      }

      &-filter {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &.--priority {
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.47) 53.65%, #000000 100%);
          opacity: 0.72;
        }
      }

      &-text {
        //position: absolute;
        //bottom: 45px;
        //left: 48px;

        svg {
          width: 319px;
          height: auto;
        }
      }

      &-footer {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
        padding: 0 48px 45px;

        position: absolute;
        left: 0;
        bottom: 0;

        @media screen and (max-width: 540px) {
          padding: 0 24px 21px;
        }
      }
    }

    &__award {
      //position: absolute;
      //right: 7%;
      //bottom: 10%;
      margin: -100% 0 -15px;
    }

    &__description {
      max-width: 852px;
      margin-left: 64px;
      padding-top: 8px;
      flex: 1;
    }

    &__price{
      @include text-style-others-s-24-w-500;
      margin-top: 32px;
      color: $color-secondary-red-400;

      &.money{
        color: $color-secondary-blue-500;
      }

      +.money{
        margin-top: 0px;
        font-size: 20px;
      }
    }

    &__time{
      @include text-style-others-s-24-w-400;
      margin-top: 16px;
    }

    &__register {
      margin-top: 48px;
    }

    &__info {
      @include text-style-others-s-24-w-400;
      margin-top: 16px;
      line-height: 2;
      color: #218a3d;

      a {
        color: #218a3d;
      }
    }
  }

  p{
    margin-bottom: 10px;
    @include text-style-texts-s-18-w-400;

    &:last-child{
      margin-bottom: 0;
    }
  }

  .certificate{
    display: flex;
    justify-content: center;
    align-items: center;

    &__title{
      margin-bottom: 18px;
    }

    &__description{
      width: 100%;
      max-width: 645px;
      margin-right: 124px;
      // padding-top: 195px;
    }

    &__img{
      flex: 1;
      width: 100%;
      max-width: 460px;
      border-radius: 27px;
      object-fit: cover;
    }

    &__text{
      @include text-style-texts-s-22-w-400;
    }
  }
}

.course-teachers {

  .c-teacherCard {
    max-width: 364px;
  }

  &__title{
    margin-bottom: 50px;
  }

  &__items{
    display: grid;
    // grid-template-columns: repeat(auto-fill, max(364px, 1fr));
    grid-template-columns: repeat(auto-fit, minmax(294px, 1fr));
    justify-content: center;
    column-gap: 18px;
    row-gap: 32px;
  }
}
