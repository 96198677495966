.error{
  
  &__wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 560px;
    margin: 5% auto;
  }

  &__number{
    @include title-404-desktop;
    margin-bottom: 8px;
    color: $color-primary-500;
  }

  &__text{
    @include descroption-404-desktop;
    margin-bottom: 72px;
  }
}