@mixin text-style-others($size, $weight: 400, $line-height: $size) {
  font-family: Montserrat;
  font-size: $size;
  font-weight: $weight;
  font-style: normal;
  line-height: $line-height;
  letter-spacing: 0.02em;
  text-decoration: none;
}

@mixin text-style-titles($size, $line-height: $size) {
  font-family: Montserrat;
  font-size: $size;
  font-weight: 600;
  font-style: normal;
  line-height: $line-height;
  letter-spacing: 0.02em;
  text-decoration: none;
}

@mixin text-style-texts($size, $weight: 400, $line-height: 146%) {
  font-family: Arial;
  font-size: $size;
  font-weight: $weight;
  font-style: normal;
  line-height: $line-height;
  text-decoration: none;
}
@mixin text-style-others-s-10-w-400{
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  line-height: 138%;
  letter-spacing: 0.02em;
  text-decoration: none;
}

@mixin text-style-others-s-24-w-400 {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.02em;
  text-decoration: none;
}
@mixin text-style-others-s-24-w-500 {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.02em;
  text-decoration: none;
}
@mixin text-style-others-s-22-w-400 {
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.02em;
  text-decoration: none;
}
@mixin text-style-others-s-22-w-500 {
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.02em;
  text-decoration: none;
}
@mixin text-style-others-s-22-w-600 {
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 600;
  font-style: normal;
  line-height: 138%;
  letter-spacing: 0.02em;
  text-decoration: none;
}
@mixin text-style-others-s-20-w-400 {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  line-height: 138%;
  letter-spacing: 0.02em;
  text-decoration: none;
}
@mixin text-style-others-s-20-w-500 {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  line-height: 138%;
  letter-spacing: 0.02em;
  text-decoration: none;
}
@mixin text-style-others-s-18-w-400 {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  line-height: 135%;
  letter-spacing: 0.02em;
  text-decoration: none;
}
@mixin text-style-others-s-18-w-500 {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.02em;
  text-decoration: none;
}
@mixin text-style-others-s-18-w-600 {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.02em;
  text-decoration: none;
}
@mixin text-style-others-s-16-w-400 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 135%;
  letter-spacing: 0.02em;
  text-decoration: none;
}
@mixin text-style-others-s-16-w-500 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 135%;
  letter-spacing: 0.02em;
  text-decoration: none;
}
@mixin text-style-others-s-16-w-600 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: 135%;
  letter-spacing: 0.02em;
  text-decoration: none;
}
@mixin text-style-others-s-14-w-400 {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 138%;
  letter-spacing: 0.02em;
  text-decoration: none;
}
@mixin text-style-others-s-14-w-500 {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: 138%;
  letter-spacing: 0.02em;
  text-decoration: none;
}
@mixin text-style-others-s-14-w-600 {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  line-height: 138%;
  letter-spacing: 0.02em;
  text-decoration: none;
}
@mixin text-style-others-s-12-w-400 {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  line-height: 138%;
  letter-spacing: 0.02em;
  text-decoration: none;
}
@mixin text-style-others-s-12-w-500 {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 138%;
  letter-spacing: 0.02em;
  text-decoration: none;
}
@mixin text-style-others-s-12-w-600 {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  line-height: 138%;
  letter-spacing: 0.02em;
  text-decoration: none;
}
@mixin text-style-titles-h-1-s-48 {
  font-family: Montserrat;
  font-size: 48px;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.02em;
  text-decoration: none;
}
@mixin text-style-titles-h-2-s-40 {
  font-family: Montserrat;
  font-size: 40px;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.02em;
  text-decoration: none;
}

@mixin text-style-titles-h-2-s-36{
  font-family: Montserrat;
  font-size: 36px;
  font-weight: 600;
  font-style: normal;
  line-height: 44px;
  letter-spacing: 0.02em;
}

@mixin text-style-titles-h-3-s-30 {
  font-family: Montserrat;
  font-size: 30px;
  font-weight: 600;
  font-style: normal;
  line-height: 144%;
  letter-spacing: 0.02em;
  text-decoration: none;
}
@mixin text-style-titles-h-4-s-24 {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
  line-height: 138%;
  letter-spacing: 0.02em;
  text-decoration: none;
}
@mixin text-style-titles-h-5-s-20 {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  line-height: 138%;
  letter-spacing: 0.02em;
  text-decoration: none;
}
@mixin text-style-titles-h-6-s-18 {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  line-height: 138%;
  letter-spacing: 0.02em;
  text-decoration: none;
}
@mixin text-style-texts-s-12-w-400 {
  font-family: Arial;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  line-height: 140%;
  text-decoration: none;
}
@mixin text-style-texts-s-24-w-400 {
  font-family: Arial;
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
  line-height: 146%;
  text-decoration: none;
}
@mixin text-style-texts-s-22-w-400 {
  font-family: Arial;
  font-size: 22px;
  font-weight: 400;
  font-style: normal;
  line-height: 128%;
  text-decoration: none;
}
@mixin text-style-texts-s-18-w-400 {
  font-family: Arial;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  line-height: 146%;
  text-decoration: none;
}
@mixin text-style-texts-s-18-w-700 {
  font-family: Arial;
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  line-height: 146%;
  text-decoration: none;
}

@mixin effect-style-shadows-blur-50 {
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
}
@mixin effect-style-shadows-blur-100 {
  box-shadow: 0px 0px 36px rgba(0, 0, 0, 0.08);
}

@mixin title-404-desktop{
  font-family: Montserrat;
  font-size: 128px;
  font-weight: 600;
  font-style: normal;
  line-height: 156px;
  letter-spacing: 0.02em;
}

@mixin descroption-404-desktop{
  font-family: Montserrat;
  font-size: 40px;
  font-weight: 400;
  font-style: normal;
  line-height: 49px;
  letter-spacing: 0.02em;
  text-align: center;
} 

@mixin title-404-mobile{
  font-family: Montserrat;
  font-size: 64px;
  font-weight: 600;
  font-style: normal;
  line-height: 78px;
  letter-spacing: 0.02em;
}
@mixin descroption-404-mobile{
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  line-height: 138%;
  letter-spacing: 0.02em;
}