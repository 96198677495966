.header {
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
  background-color: $color-achromatic-white;
  transition: top 1s, box-shadow 0.5s;

  &.--sticky {
    @include effect-style-shadows-blur-50;

    .header__top {
      padding: 24px 0;
    }

    .search__wrap {
      margin: 21px auto;
    }
  }

  &.--shadow{
    @include effect-style-shadows-blur-50;
  }

  &.--active-popup {

    .submenu {
      top: 95px;
      opacity: 1;
      // height: auto;
      pointer-events: auto;
    }

    .header__course-button {
      color: $color-achromatic-white;
      background-color: $color-primary-500;

      svg {
        transform: rotate(180deg);

        path {
          fill: $color-achromatic-white;
        }
      }
    }
  }

  &__mobile-open {
    display: none;
  }

  .menu-mobile {
    position: fixed;
    top: 0;
    bottom: 0;
    left: -398px;
    z-index: 10;
    width: 100%;
    max-width: 398px;
    height: 100%;
    overflow: hidden;
    background-color: $color-achromatic-white;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
    transition: left 0.4s;

    &.open {
      left: 0;
    }

    &.hidden {
      left: -398px;
    }

    .scrollbar-thumb {
      width: 3px;
      background-color: $color-primary-500;
    }

    .scrollbar-track {
      width: 3px;
      background-color: $color-achromatic-gray-100;
    }


    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px 12px 93px;
    }

    &__menu-close {
      position: absolute;
      top: 33px;
      right: 17px;
    }

    &__course-close {
      position: absolute;
      top: 33px;
      left: -50px;
      transform: rotate(-90deg);
      transition: left 0.3s;
    }

    &__logo-nav {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__logo {
      margin-bottom: 54px;
      
      svg {
        width: 201px;
        height: 41px;
      }
    }

    &__nav {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 62px;

      &-item {
        margin-bottom: 32px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }

    &__submenu {

      &-open {
        margin-bottom: 26px;
      }
    }

    &__course {

      &-button {
        @include text-style-others-s-18-w-500;

        display: flex;
        align-items: center;
        padding: 10px 15px 10px 27px;
        color: $color-achromatic-white;
        background-color: $color-primary-500;
        border-radius: 56px;
        transition-duration: 0.3s;

        svg {
          width: 20px;
          height: 20px;
          margin-left: 9px;
          transform: rotate(-90deg);
          transition: transform 0.3s;

          path {
            fill: $color-achromatic-white;
            transition-duration: 0.3s;
          }
        }
      }
    }

    &__contacts {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 150px;
    }

    &__block {
      margin-bottom: 18px;
      text-align: center;

      &:last-child {
        margin-bottom: 0px;
      }
    }

    &__phone {
      @include text-style-others-s-14-w-500;
      width: 200px;
      margin-bottom: 4px;
      color: $color-primary-500;
    }

    &__text {
      @include text-style-others-s-12-w-400;
    }

    &__email {
      @include text-style-others-s-14-w-500;
      color: $color-primary-500;
    }

    &__address {
      @include text-style-others-s-12-w-400;
    }

    &__socials {
      margin-top: 34px;

      &-icon {
        margin-right: 24px;

        svg {
          width: 26px;
          height: 26px;

          path {
            fill: $color-primary-500;
          }
        }

        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }

  .b-link {
    @include text-style-others-s-18-w-500;
  }

  &__logo-nav,
  &__links,
  &__phone-search,
  &__top,
  &__bottom {
    display: flex;
  }

  &__top {
    justify-content: space-between;
    width: 100%;
    padding: 32px 0;
  }

  &__bottom {
    padding: 24px 0;
    overflow-x: scroll;
    border-top: 1px solid $color-achromatic-gray-200;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    &-link {
      margin-right: 36px;
      white-space: nowrap;

      a {
        @include text-style-others-s-16-w-500;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  &__links,
  &__phone-search,
  &__logo-nav {
    align-items: center;
  }

  &__logo {
    margin-right: 56px;
  }

  &__link {
    margin-right: 32px;

    &:last-child {
      margin-right: 0px;
    }
  }

  &__course {
    margin-right: 36px;

    &-button {
      @include text-style-others-s-18-w-500;

      display: flex;
      align-items: center;
      padding: 10px 15px 10px 27px;
      color: $color-primary-500;
      border: 1px solid $color-primary-500;
      border-radius: 56px;
      transition-duration: 0.3s;

      svg {
        margin-left: 8px;
        transition: transform 0.3s;
        
        path {
          fill: $color-primary-500;
          transition-duration: 0.3s;
        }
      }

      &:hover {
        color: $color-achromatic-white;
        background-color: $color-primary-500;

        svg {

          path {
            fill: $color-achromatic-white;
          }
        }
      }
    }
  }

  &__search {

    .b-iconButton {
      width: auto;
      height: auto;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__phone {
    @include text-style-others-s-18-w-500;
    margin-right: 26px;
  }

  .submenu {
    @include effect-style-shadows-blur-50;
    @include text-style-others-s-18-w-400;
    position: absolute;
    top: 100px;
    left: 25%;
    z-index: 1;
    display: flex;
    max-width: 835px;
    padding: 32px 42px 58px;
    overflow: hidden;
    background-color: $color-achromatic-white;
    border-radius: 21px;
    opacity: 0;
    transition: opacity 0.3s, top 0.3s;
    pointer-events: none;

    &__title {
      margin-bottom: 20px;
    }

    &__list {
      display: flex;

      ul {
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__link {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__container {
      margin-right: 52px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .search {
    @include effect-style-shadows-blur-50;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: $color-achromatic-white;
    opacity: 0;
    transition: opacity 0.5s;
    pointer-events: none;
    
    &__wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 29px auto;
    }
    
    &__container {
      display: flex;
      align-items: center;
      // width: 518px;
      width: 100%;
    }

    &__icon {
      margin-right: 20px;

      svg {
        width: 38px;
        height: 38px;
      }
    }

    &__input {
      @include text-style-others-s-22-w-500;
      width: 100%;
      padding: 0;
      color: $color-achromatic-black;
      border-width: 0px;

      &::placeholder {
        @include text-style-others-s-22-w-500;
        color: $color-achromatic-gray-500;
      }
    }

    &__close {

      svg {
        width: 38px;
        height: 38px;
      }
    }

    &.--active-popup {
      opacity: 1;
      pointer-events: auto;
      
    }
  }

}

.searchForm {
  position: absolute; 
  right: 0;
  left: 0;
  display: none;
  box-sizing: content-box;
  max-width: 1414px;
  max-height: 360px;
  margin-top: 14px; 
  margin-right: auto; 
  margin-left: auto;
  padding: 32px 24px 32px 52px;
  overflow: hidden;
  background-color: $color-achromatic-white;
  border-radius: 21px;
  transition: max-height 0.3s; 
  @include effect-style-shadows-blur-100;
  @include text-style-others-s-22-w-500;

  &__content{
    width: 100%;
    max-height: 360px;
    padding-right: 36px;
    overflow: auto;

    .scrollbar-track {
      background: none;
    }
    
    .scrollbar-thumb{
      width: 6px;
      background-color: $color-achromatic-gray-200;
    }
  }

  &.--active {
    display: block;
  }

  &__container{
    display: grid;
  }

  a {
    margin-bottom: 40px;
    color: $color-achromatic-black;
    cursor: pointer;
    transition-duration: 0.3s;
    
    &:hover {
      color: $color-primary-500;
    }

    &:last-child {
      margin-bottom: 0;
    }

    mark {
      color: $color-primary-500;
      background-color: transparent;
    }
  }
  

  &__gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 106px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
    
    &.--active{
      opacity: 1;
    }
  }
}

.autoComplete_wrapper {
  width: 100%;
}