.courses-page {
  position: relative;
}

.courses {

  &__no-result {
    width: 100%;

    h4 {
      max-width: 384px;
      margin: 0 auto;
      text-align: center;
    }
  }

  &__filter-btn {
    display: none;
    max-width: 220px;
    transition: width 0.3s, margin 0.3s, margin-right 0.3s;
  }

  &__wrapper {
    margin-top: 22px;
    margin-bottom: 31px;

    &.--active {

      .courses__filter-btn {
        max-width: 0px;
        height: 0px;
        margin: 0;
        overflow: hidden;
      }
    }
  }

  &__categories {
    margin-top: 88px;
    margin-bottom: 50px;
  }

  &__content {
    margin-bottom: 140px;
  }

  &__filter-cards {
    display: flex;
    // justify-content: space-between;
  }

  .filter {
    // position: relative;
    max-width: 252px;
    margin-right: 72px;
    background-color: $color-achromatic-white;

    &__add {
      position: fixed;
      bottom: 0;
      left: -340px;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 340px;
      padding: 24px 44px;
      background-color: $color-achromatic-white;
      border-top: 1px solid $color-achromatic-gray-500;
      transition: left 0.3s;

      .b-button {
        width: 100%;
        height: 54px;
      }
    }

    &__remove {
      height: 0px;
      overflow: hidden;
      color: $color-primary-500;
      opacity: 0;
      cursor: pointer;
      transition: opacity 0.3s;
      transition: 0.3s;
      @include text-style-others-s-16-w-400;

      &:hover {
        color: $color-secondary-blue-300;
      }

      &.-desk {
        margin-top: 40px;
        padding-top: 24px;
        border-top: 1px solid $color-achromatic-gray-500;

        &.--active {
          height: auto;
          opacity: 1;
        }
      }
    }

    &__close {
      position: absolute;
      top: 21px;
      right: 21px;
      display: none;

      .b-iconButton {
        width: 34px;
        height: 34px;

        svg {
          width: 24px;
          height: 24px;
        }
      }


    }

    .toggle_wrap {
      display: flex;
      align-items: center;
      margin-bottom: 32px;

      &-text {
        margin-left: 12px;
        cursor: pointer;
        @include text-style-others-s-18-w-400;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__block {
      margin-right: 20px;
      padding: 40px 0;
      border-bottom: 1px solid $color-achromatic-gray-500;

      &:first-child {
        padding-top: 0px;
      }

      &:last-child {
        padding-bottom: 0px;
        border-width: 0px;
      }
    }

    .hours, .months {
      margin-bottom: 20px;
    }

    .months {
      display: none;
    }

    &__item {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0px;
      }

      .b-checkBox {
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &-title {
        margin-bottom: 22px;

        &_small {
          margin-bottom: 6px;
        }
      }

      &-links {
        &_scrollbar {
          max-height: 300px;
          padding-right: 5px;
          overflow: hidden;

          .scroll-content {
            display: flex;
            flex-direction: column;
          }

          .scrollbar-track {
            width: 2px;
            background-color: transparent;
          }

          .scrollbar-thumb {
            background-color: $color-primary-500;
          }
        }
      }

      &-link {
        @include text-style-others-s-16-w-400;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0px;
        }

        a {
          @include text-style-others-s-16-w-400;
          cursor: pointer;
        }
      }

      &-text {
        @include text-style-others-s-16-w-400;
        margin-bottom: 20px;
      }
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  &__cards {

    .cards {
      display: grid;
      grid-column-gap: 18px;
      grid-row-gap: 32px;
      grid-template-columns: repeat(3, 1fr);
    }

    .c-card {

      &__wrapper {
        padding: 18px 24px 22px;
      }

      &__title {
        @include text-style-others-s-18-w-600;
      }

      .b-status {
        @include text-style-others-s-14-w-400;

        &__start, &__close {

          &::before {
            width: 8px;
            height: 8px;
          }
        }
      }

      &__rating {
        display: flex;
        align-items: center;
        margin-top: 20px;

        &-text {
          @include text-style-others-s-16-w-500;
          color: $color-primary-500;
          padding-right: 5px;
          font-weight: bold;
        }
      }

      &__reviews-link {
        z-index: 10;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      .stars_container {
        display: inline-block;
        position: relative;
        width: 68px;
        height: 30px;
        background: #dadada;
        background-size: 100% 100%;

        .stars_bg {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 2;
          background: url(../assets/images/img/stars.png) no-repeat center;
          border: 1px solid white;
        }

        .stars_progress {
          width: 50%;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          background: #e76110;
        }

        + * {
          margin-left: 10px;
        }
      }

      &__price {
        @include text-style-others-s-16-w-500;
        margin-top: 20px;
      }

      &__time {
        @include text-style-others-s-16-w-400;
        margin-top: 6px;
      }

      &__tags {
        @include text-style-others-s-14-w-400;
        margin-top: 20px;
      }
    }
  }


  .feedback {

    .b-input {
      width: 100%;

      input {
        width: 100%;
      }
    }
  }

  .filter__range {
    width: 242px;
    margin: 20px 0;
  }

  .noUi-touch-area {
    cursor: pointer;
  }

  .noUi-horizontal {

    .noUi-handle {
      width: 28px;
      height: 28px;
      background: $color-primary-500;
      border: 0px solid $color-primary-500;
      border-radius: 100%;
      box-shadow: none;
      cursor: default;

      &::before, &::after {
        display: none;
      }
    }

    .noUi-connects {
      position: absolute;
      top: 7px;
      height: 2px;
      background: $color-achromatic-gray-200;
    }
  }

  .noUi-connect {
    background: $color-primary-500;
  }

  .noUi-target {
    background-color: transparent;
    border: 0px solid $color-achromatic-white;
    box-shadow: none;
  }


  &__accordion {
    display: none;

    li {
      a {
        color: $color-achromatic-black;
      }
    }
  }

}

.layout {

  &.--active {
    z-index: 1;
    opacity: 0.7;
    transition: 0.3s linear;
  }
}
