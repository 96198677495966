.modal-v2 {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);

  .modal-body {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 30px 50px;
    border: 1px solid #888;
    border-radius: 27px;
  }

  .modal-content {
    .b-button {
      margin: 30px auto 0px;
    }

    .modal {
      &-title{
        font-family: Montserrat;
        font-size: 30px;
        font-weight: 600;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.02em;
        text-decoration: none;
        text-align: center;
      }
      &-text{
        text-align: center;
      }
    }
  }

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    color: #aaaaaa;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
  }

  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
}
