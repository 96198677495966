.reg-email {

  .form {
    margin-bottom: 150px;
    color: $color-primary-500;
    background-color: $color-achromatic-white;

    .hidden {
      display: none;
    }

    .w-100 {
      width: 100%;
    }

    .w-75 {
      width: 75%;
    }

    .w-50 {
      width: 50%;
    }

    .w-25 {
      width: 25%;
    }

    .text-center {
      text-align: center;
    }

    &-wrapper {
      max-width: 1000px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      border: 1px solid $color-primary-800;
      border-radius: 24px;
      padding: 30px;
      margin: 0 auto;
    }

    &-blocks {
      width: 100%;
    }

    &-block {
      margin-bottom: 50px;
    }

    &-control {
      display: flex;
      flex-direction: column;
      @include text-style-others-s-18-w-400;
      margin-bottom: 20px;
      width: 100%;

      &:last-child {
        margin-bottom: 0px;
      }

      &_education {
        flex-direction: column;

        .form-control__title {
          text-align: center;
        }

        select {
          margin-top: 10px;
        }
      }

      &_email {
        flex-direction: column;
        justify-content: space-between;
        align-items: baseline;

        button {
          margin-top: 5px;
        }
      }

      &_submit-code {
        flex-direction: column;
        align-items: baseline;
      }

      &__input {
        color: $color-achromatic-black;

        &::placeholder {
          opacity: 0.7;
        }
      }

      &__title {
        margin-right: 10px;
      }

      &__date {
        max-width: 125px;
        margin-right: 25px;
      }

      &__age {
        max-width: 75px;
      }


      &__info {
        @include text-style-others-s-12-w-400;
        margin: 5px 0;

        &_error {
          color: $color-secondary-red-500;
        }

        &_success {
          color: $color-primary-400;
        }
      }
    }

    &-header {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0px;
      }

      &_main-title {
        margin-bottom: 20px;
      }

      &__title {
        margin-right: 10px;
      }

      &__subtitle {
        @include text-style-others-s-16-w-400;
        line-height: 1;
        color: $color-achromatic-black;
      }
    }
  }

}
