.footer {
  color: $color-achromatic-white;
  background-color: $color-primary-800;

  h5 a{
    color: $color-achromatic-white;
  }
  
  &__accordion{
    display: none;

    li {
      a {
        color: $color-achromatic-white;
      }
    }
  }

  &__top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 69px;
  }

  &__wrapper {
    padding-top: 82px;
    padding-bottom: 24px;
  }

  &__logos-lists {
    display: flex;
  }

  &__logos {
    display: flex;
    flex-direction: column;
    margin-right: 100px;
  }

  &__logo {
    margin-bottom: 56px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__lists {
    display: flex;
  }

  &__list {
    width: 100%;
    max-width: 221px;
    margin-right: 47px;

    &:last-child {
      margin-right: 0;
    }

    &-title {
      margin-bottom: 20px;
    }

    &-link {
      @include text-style-others-s-18-w-400;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__contacts {

    &-block {
      display: flex;
      flex-direction: column;
      max-width: 236px;
      margin-bottom: 26px;
    }

    &-number {
      @include text-style-others-s-22-w-600;
      margin-bottom: 4px;
    }

    &-text {
      @include text-style-others-s-16-w-400;
    }

    &-mail {
      @include text-style-others-s-22-w-600;
    }

    &-address {
      @include text-style-others-s-20-w-400;
    }
  }

  &__socials {
    display: flex;
    margin-top: 40px;

    svg {
      width: 28px;
      height: 28px;
    }
  }

  &__social {

    &-icon {
      margin-right: 24px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__copyright {
    @include text-style-others-s-14-w-400;
    width: 100%;
    max-width: 462px;
    margin-right: 67px;
  }

  &__bottom {

    &-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 22px 0;
      border-top: 1px solid rgba($color-achromatic-white, 0.2);
    }

    &-links {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -10px;
      margin-left: -30px;

      & > *{
        margin-bottom: 10px;
        margin-left: 30px;
      }
    }
    
    &-link {
      @include text-style-others-s-14-w-400;
      // flex: 1 1 auto;
    }
  }
}