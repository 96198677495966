.main {

  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -3;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow: hidden;

    svg{
      transition-duration: 0.3s;
    }

    &-1 {
      width: 100%;
      // width: 560px;

      &.bg-dots-1 {

        svg{
          position: absolute;
          top: 110px;
          // left: -125px;
          left: -7%;
        }
      }
    }

    &-2 {
      // width: 270px;
      width: 100%;

      &.bg-dots-2 {

        svg{
          position: absolute;

          top: 732px;
          // left: 730px;
          // right: 662px;
          right: 32%;
        }

      }
    }

    &-3 {
      // width: 560px;
      width: 100%;

      &.bg-dots-3 {

        svg{
          position: absolute;

          top: 301px;
          // right: 137px;
          right: 5%;
        }

      }
    }
  }

  &-page {
    padding-top: 40px;
  }

  &__content {
    margin-bottom: 140px;
  }

  .hello-screen {
    display: flex;
    margin-bottom: 99px;
    padding-left: 31px;

    &__title {
      @include text-style-titles-h-1-s-48;
      margin-bottom: 20px;
    }

    &__text {
      @include text-style-texts-s-24-w-400;
      margin-bottom: 42px;
    }

    &__counters {
      display: flex;
    }

    &__counter {
      margin-right: 100px;
      text-align: center;

      &:last-child {
        margin-right: 0;
      }
    }


    &__img {
      // max-width: 532px;
      margin-right: 98px;
      // flex: 1;

      img{
        // object-fit: cover;
        width: 100%;
        max-width: 532px;
        max-height: 100%;
      }
    }

    &__container {
      width: 100%;
      max-width: 736px;
      padding-top: 38px;
      padding-bottom: 79px;
      // flex: 1;
    }
  }

  .types {

    &__container {
      display: flex;
      margin-top: 48px;
    }
  }

  .direction {

    &__container {

      .scrollbar-thumb {
        height: 2px;
        background-color: $color-primary-500;
      }

      .scrollbar-track {
        height: 2px;
        background-color: $color-achromatic-gray-100;
      }

      .scroll-content {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(298px, 1fr));
        column-gap: 24px;
        row-gap: 26px;
      }
    }
  }
}
