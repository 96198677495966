.index-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 10% 0;
  font-family: Arial, Helvetica, sans-serif;

  a {
    &:hover {
      opacity: 1 !important;
    }
  }

  &__title {
    margin-bottom: 20px;
    font-size: 36px;
  }

  &__links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 50%;
  }

  &__link {
    position: relative;
    display: flex;
    justify-content: center;
    width: calc(50% - 20px);
    margin-right: 5px;
    margin-bottom: 10px;
    padding: 10px 0;
    color: #1c156c;
    border: 1px solid $color-primary-500;

    &:hover {
      color: white;
      background: $color-primary-500;

      .index-page__preview {
        z-index: 99999;
        display: block;
        opacity: 1;
      }
    }
  }

  &__preview {
    position: fixed;
    z-index: 100;
    display: none;
    width: calc(25% - 70px);
    overflow: hidden;
    background-color: #ffffff;

    &_right {
      top: 50px;
      right: 50px;
      bottom: 50px;
    }

    &_left {
      top: 50px;
      bottom: 50px;
      left: 50px;
    }

    &-container {
      position: relative;
      width: 100%;
      height: 100%;
    }

    &-frame {
      width: 1440px;
      height: 100%;
      border: 0;
      transform: scale(0.25);
      transform: scale(0.25);
      transform: scale(0.25);
      transform: scale(0.25);
      transform: scale(0.25);
      transform-origin: 0 0;
      transform-origin: 0 0;
      transform-origin: 0 0;
      transform-origin: 0 0;
      transform-origin: 0 0;

      &-container {
        width: 100%;
        height: 1000vh;
        padding: 0;
        overflow: hidden;
      }
    }
  }
}
