/* ================================ Modules ================================ */

.m-courses {
  
  .scrollbar-thumb {
    height: 4px;
    background-color: $color-primary-500;
  }

  .scrollbar-track {
    height: 4px;
    background-color: $color-achromatic-gray-100;
  }

  .cards-2 {
    display: grid;
    grid-column-gap: 24px;
    grid-row-gap: 32px;
    grid-template-columns: repeat(2, 1fr);

    .c-card {

      &__title{
        @include text-style-titles-h-4-s-24;
      }

      &__wrapper {
        padding: 32px 40px;
      }

      &__info {
        margin-top: 38px;
      }

      .b-status {
        @include text-style-others-s-18-w-400;
      }

      &__price {
        @include text-style-others-s-24-w-500;
        margin-top: 32px;
      }

      &__time {
        @include text-style-others-s-20-w-400;
        margin-top: 10px;
      }

      &__tags {
        @include text-style-others-s-18-w-400;
        margin-top: 32px;
      }
    }
  }


  .cards-3-2 {
    display: grid;
    grid-column-gap: 24px;
    grid-row-gap: 32px;
    grid-template-columns: repeat(3, 1fr);
    transition-duration: 0.3s;

    .c-card-hidden{
      
      .c-card{

        height: 100%;
      }
    }

    &.--hidden{
      // height: 0px;
      overflow: hidden;

      .c-card-hidden{
        display: none;

        .c-card{

          height: 100%;
        }
      }
    }

    .c-card {

      &__title{
        @include text-style-others-s-22-w-600;
      }

      &__wrapper {
        padding: 20px 32px 26px;
      }

      &__info {
        margin-top: 24px;
      }

      .b-status {
        @include text-style-others-s-16-w-400;
      }

      &__price {
        @include text-style-others-s-20-w-500;
        margin-top: 24px;
      }

      &__time {
        @include text-style-others-s-18-w-400;
        margin-top: 10px;
      }

      &__tags {
        @include text-style-others-s-16-w-400;
        margin-top: 24px;
      }
    }
  }

  .cards-4-3-2-1 {
    display: grid;
    grid-column-gap: 18px;
    grid-row-gap: 32px;
    grid-template-columns: repeat(4, 1fr);
  }

  &.--active {

    .cards-3-2.--hidden{
      // height: auto;
      margin-top: 28px;
    }

    .hidden {
      height: 100%;
      opacity: 1;
    }

    .m-courses__button {
      display: none;
      overflow: hidden;
      opacity: 0;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    margin-top: 54px;
  }

  .b-cardButton {
    height: 100%;
    opacity: 1;
    transition: box-shadow 0.3s, opacity 0.8s;
  }

  &__container{
    overflow: inherit !important;
  }
}

.m-feedback {
  position: relative;
  display: flex;
  padding: 100px 135px 94px 132px;
  overflow: hidden;
  background-color: $color-achromatic-white;
  border-radius: 27px;
  @include effect-style-shadows-blur-100;

  &-bg {

    &-1,
    &-2 {
      z-index: 0;
    }

    &-1 {

      &.bg-dots-3 {
        top: -330px;
        left: -310px;
        transform: scaleX(-1) rotate(90deg);
      }
    }

    &-2 {

      &.bg-dots-3 {
        top: 200px;
        left: 50px;
        transform: rotate(270deg);
        // transform: rotate(90deg) scaleX(1);
      }
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
  }

  &__confindencial {
    width: 282px;

    a {
      @include text-style-others-s-14-w-400;
    }

    @include text-style-others-s-14-w-400;
  }

  &__input {

    margin-bottom: 34px;

    &:last-child {
      margin-bottom: 0px;
    }

    .b-input {
      width: 100%;

      input {
        width: 100%;

        &::placeholder {
          @include text-style-others-s-16-w-400;
        }
      }
    }
  }

  &__container {
    z-index: 1;
    flex: 1;
    margin-right: 126px;
  }

  &__form {
    z-index: 1;
    flex: 1;
  }

  &__text {
    @include text-style-texts-s-18-w-400;
    margin-top: 18px;
    font-size: 19px;
  }
}

.m-faqs {
  margin: 180px 0 128px;

  &-title {
    margin-bottom: 56px;
  }

  &-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(501px, 1fr));
    column-gap: 44px;
    row-gap: 46px;
  }
}

.m-checks {
  
  &__title {
    margin-bottom: 80px;
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    column-gap: 66px;
    row-gap: 56px;
  }
}

.feedback {
  height: 350px;

  &-text {

    &.active {
      overflow: visible;
      background: white;
      position: relative;
      z-index: 2;
      padding: 15px;
      box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
      border-radius: 10px;

      span {
        height: 100%;
        overflow-y: scroll;
      }
    }

    span {
      height: 100%;
      overflow-y: hidden;
    }
  }

  &__more {
    display: none;
    margin-top: 15px;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    line-height: 144%;
    letter-spacing: 0.02em;
    text-decoration: none;
    color: green;
    cursor: pointer;
  }
}