.contacts{

  &-page {

    .c-breadcrumbs {
      margin-bottom: 32px;
    }
  }

  &__wrapper{
    display: flex;
  }

  &__block {
    margin-right: 72px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__info{
    display: flex;
  }

  &__map{
    flex: 1;
    overflow: hidden;
    border-radius: 34px;
  }

  &__description{
    flex: 1;
    margin-left: 88px;
    padding-top: 24px;
  }

  &__subtitle{
    @include text-style-texts-s-18-w-400;
    margin: 12px 0 32px;
  }

  &__item{
    margin-bottom: 20px;

    &:last-child{
      margin-bottom: 0;
    }
  }

  &__link{
    @include text-style-others-s-22-w-600;
  }

  &__number {
    @include text-style-others-s-22-w-600;
    width: 214px;
    color: $color-achromatic-black;

    &-text {
      @include text-style-others-s-16-w-400;
      margin-top: 5px;
    }
  }

  &__mail {
    @include text-style-others-s-22-w-600;
    
    &-text {
      margin-top: 20px;
      @include text-style-others-s-18-w-400;
    }
  }

  &__socials{

    display: flex;
    margin-top: 56px;

    &-icon{
      margin-right: 24px;

      svg{

        width: 32px;
        height: 32px;

        path{
          fill: $color-primary-500;
        }
      }

      &:last-child{
        margin-right: 0;
      }
    }
  }

  &__feedback{
    margin: 140px 0 140px;
  }
}