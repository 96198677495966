@media screen and (max-width: 1515px) {

  .header {

    &.--active-popup {

      .submenu {
        left: 0;
        transition: left 0.4s;
      }

      .menu-mobile__course-close {
        left: 17px;
        transition: left 0.3s;
      }

    }

    &__links {
      display: none;
    }

    &__mobile {

      &-open {
        display: block;
        margin-right: 25px;
      }
      &-hidden {
        display: none;
      }
    }

    .submenu {
      position: fixed;
      top: 88px;
      left: -400px;
      z-index: 20;
      flex-direction: column;
      width: 100%;
      max-width: 394px;
      height: 100%;
      padding: 36px 0px 0px 12px;
      border-radius: 0px;
      opacity: 1;
      opacity: 0;
      box-shadow: none;
      transition: left 0.4s;

      &__container {
        margin-right: 0;
        margin-bottom: 30px;
        padding-left: 12px;

        &:last-child {
          margin-bottom: 0;
        }
      }
      &__title {
        @include text-style-others-s-16-w-600;
        margin-bottom: 14px;
      }

      &__link {
        @include text-style-others-s-14-w-400;
        margin-bottom: 14px;
      }
    }
  }

  .container {
    padding-right: 40px;
    padding-left: 40px;
  }

  .searchForm {
    max-width: 90%;
  }

  .courses {

    .cards {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .footer {

    &__wrapper {
      padding: 82px 40px 24px;
    }

    &__logos {
      margin-right: 70px;
    }

    &__contacts {
      display: flex;
      flex-wrap: wrap;
      margin-top: 69px;

      &-socials {
        width: 100%;
      }

      &-block {
        margin-right: 64px;
        margin-bottom: 28px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__list {
      margin-right: 70px;
    }

    &__socials {
      margin-top: 12px;
    }
  }

  .main-bg {
    // display: none;
  }
}

@media screen and (max-width: 1280px) {

  .m-feedback{
    padding: 107px 75px 94px 69px;

    &__container {
      margin-right: 63px;
    }
  }

  .b-cardButton {
    max-width: 100%;
    height: 618px;
  }

  .m-courses {

    .cards-3-2 {

      .c-card-hidden {
        display: none;
      }
    }

    .cards-3-2.--hidden {

      .c-card-hidden {
        display: block;
      }
    }
  }

  .main {

    .hello-screen {
      margin-bottom: 18px;
      padding-left: 0;
    }

    &-bg {

      &-1 {

        &.bg-dots-1 {

          svg {
            top: 110px;
            left: -33%;
          }
        }
      }

      &-2 {

        &.bg-dots-2 {

          svg {
            top: 620px;
            right: 17%;
          }
        }
      }

      &-3 {

        &.bg-dots-3 {

          svg {
            top: 301px;
            right: -16%;
          }
        }
      }
    }
  }

  .m-courses {

    .cards-3-2 {
      grid-template-columns: repeat(2, 1fr);
    }

    .cards-4-3-2-1 {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .index {

    &-page {

      &__links {
        width: 80%;
      }

      &__link {
        width: 100%;

        &:hover {

          .index-page__preview {
            display: none;
          }
        }
      }
    }
  }

  .contacts {

    &__description {
      margin-left: 76px;
    }
  }

  .course {

    &__modules-bg-1.bg-dots-1{
      top: -114px;
      right: -269px;
    }

    &__modules-bg-2.bg-dots-3{
      bottom: -399px;
      left: -8px;
    }

    .m-checks{

      &__container{
        column-gap: 44px;
      }
    }

    &-info__img-text {
      left: 30px;
    }

    &__certificate {
      margin-bottom: 201px;
    }

    .certificate {

      &__description {
        margin-right: 72px;
      }
    }
  }

  .footer {

    &__top {
      flex-wrap: wrap;
    }
  }
}

@media screen and (max-width: 1190px) {

  .header {

    &.--active-popup {

      .submenu {
        left: 0;
        transition: left 0.4s;
      }

      .menu-mobile__course-close {
        left: 17px;
        transition: left 0.3s;
      }

    }

    .submenu {
      position: fixed;
      top: 88px;
      left: -400px;
      z-index: 20;
      flex-direction: column;
      width: 100%;
      max-width: 394px;
      height: 100%;
      padding: 36px 0px 0px 12px;
      border-radius: 0px;
      opacity: 1;
      opacity: 0;
      box-shadow: none;
      transition: left 0.4s;

      &__container {
        margin-right: 0;
        margin-bottom: 30px;
        padding-left: 12px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &__title {
        @include text-style-others-s-16-w-600;
        margin-bottom: 14px;
      }

      &__link {
        @include text-style-others-s-14-w-400;
        margin-bottom: 14px;
      }
    }

    &__top {
      padding: 24px 0;
    }

    &__mobile {

      &-open {
        display: block;
        margin-right: 25px;
      }
    }

    &__links {
      display: none;
    }

    &__logo {
      margin-right: 0px;
    }

    &__wrapper{

      &.--active{
        opacity: 0.3;
        pointer-events: none;
      }
    }
  }

  .main {

    .direction {

      &__container {
        grid-template-columns: repeat(auto-fit, minmax(289px, 1fr));
      }
    }

    .c-profession {
      height: 304px;
    }

    .types {

      &__container {
        flex-direction: column;

        .c-courseType {
          display: flex;
          align-items: center;
          margin-right: 0;
          margin-bottom: 22px;
          padding: 46px 40px 52px 44px;

          &:last-child {
            margin-bottom: 0;
          }

          &__img {
            margin-right: 40px;
            margin-bottom: 0;

            svg {
              width: 84px;
              height: 84px;
            }
          }

          &__title {
            width: 100%;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .m-faqs {

    &-container {
      grid-template-columns: repeat(auto-fit, minmax(440px, 1fr));
    }
  }

  .teachers-page {

    .visible {
      grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    }
  }

  .teacher {

    .c-qualification__date {
      width: 40%;
    }
  }

  .footer {

    &__logos {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 0;
      margin-bottom: 64px;
    }

    &__logo {
      margin-right: 56px;
      margin-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }

    &__contacts {
      margin-top: 64px;

      &-block {
        margin-right: 35px;
      }
    }

    &__logos-lists {
      flex-wrap: wrap;
      width: 100%;
    }

    &__list {
      margin-right: 60px;
    }

    &__bottom {

      &-links {
        flex: 1;
        margin-left: 0;
      }

      &-link {
        margin-right: 30px;
        margin-left: 0;
      }
    }

    &__copyright {
      flex: 1;
      margin-right: 22px;
      margin-right: 0;
      margin-bottom: 26px;
    }
  }
}

@media screen and (max-width: 1024px) {

  .b-scrollTopButton {
    display: none;
  }

  .m-courses {

    .cards-2 {

      .c-card {

        &__title {
          @include text-style-others-s-22-w-600;
        }

        &__wrapper {
          padding: 20px 32px 26px;
        }

        &__info {
          margin-top: 24px;
        }

        .b-status {
          @include text-style-others-s-16-w-400;
        }

        &__price {
          margin-top: 24px;
        }

        &__tags {
          margin-top: 24px;
        }
      }
    }
  }

  .main {

    &-bg {

      &-1 {

        &.bg-dots-1 {

          svg {
            top: 110px;
            left: -39%;
          }
        }
      }

      &-2 {

        &.bg-dots-2 {

          svg {
            top: 732px;
            right: 10%;
          }
        }
      }

      &-3 {

        &.bg-dots-3 {

          svg {
            top: 110px;
            right: -24%;
          }
        }
      }
    }

    .hello-screen {
      margin-bottom: 0;

      &__img {
        margin-right: 55px;
      }

      &__container {
        padding-bottom: 50px;
      }
    }

    .direction__container {

      .scroll-content {
        grid-template-columns: repeat(auto-fit, minmax(271px, 1fr));
      }
    }
  }

  .teachers {

    .cards-4-3-2-1 {
      grid-column-gap: 12px;
    }
  }

  .c-teacherCard {

    &__container {
      padding: 18px 16px 64px;
    }
  }

  .teachers-page {
  }

  .c-profession {
    height: 304px;
  }

  .footer {

    &__wrapper {
      padding: 82px 40px 40px;
    }

    &__contacts {

      &-block {
        margin-right: 35px;
      }
    }
  }

  .courses {

    &__categories {
      margin-bottom: 0px;
    }

    &__content {
      margin-top: 50px;
    }

    &__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 0px;
      margin-bottom: 32px;

      &.--active {

        .search__close {
          position: absolute;
          right: 0;
          display: flex;

          .b-iconButton {
            width: 34px;
            height: 34px;

            svg {
              position: relative;
              width: 24px;
              height: 24px;

              path {
                fill: $color-achromatic-black;
              }
            }
          }
        }
      }
    }

    &__search {
      max-width: 518px;
      margin-bottom: 0;
    }

    .search {

      &__container {
        width: auto;
      }
    }

    &__filter-btn {
      display: block;
      @include text-style-others-s-16-w-500;

      svg {
        width: 22px;
        height: 22px;
        margin-right: 8px;
      }
    }

    &__search {
      flex: 1;
    }

    .filter__wrapper {
      // width: 100%;
      height: 100%;
      margin-top: 60px;
      padding: 0px 0px 35px 28px;
      overflow: scroll;
      overflow-x: hidden;

      .scrollbar-thumb {
        width: 4px;
        height: 2px;
        background-color: $color-achromatic-gray-200;
      }

      .scrollbar-track {
        width: 4px;
        height: 2px;

        background-color: transparent;
      }
    }

    .filter {
      max-width: 340px;

      &__block {
        // margin-right: 20px;
      }

      &__remove {

        &.-desk {
          display: none;
        }
      }
    }

    &__filter {
      // display: none;
      position: fixed;
      top: 0;
      left: -340px;
      width: 100%;
      max-width: 0;
      height: 100vh;
      margin-right: 0;
      margin-right: 0;
      padding-right: 10px;
      padding-bottom: 154px;

      background-color: $color-achromatic-white;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
      transition: left 0.3s;

      &.--active {
        left: 0;
        z-index: 3;

        .filter__close {
          display: block;
        }

        .filter__add {
          left: 0;

          &.--active {

            .filter__remove {
              height: auto;
              margin-top: 26px;
              opacity: 1;
            }
          }
        }
      }

      &-btn {

        svg {

          path {
            fill: $color-achromatic-white;
          }
        }
      }
    }

    .cards {

      .visible {
        grid-template-columns: repeat(auto-fit, minmax(305px, 1fr));
      }
    }
  }

  .course {

    .m-checks{

      &__container{
        // column-gap: 40px;
      }
    }

    &__modules-bg-1.bg-dots-1{
      display: none;
    }

    .course-info {
      flex-direction: column;

      &__img {
        max-width: 100%;
        max-height: 422px;
        margin-bottom: 32px;

        img {
          width: 100%;
        }
      }

      &__description {
        margin-left: 0;
      }
    }

    .module {
      padding: 64px 64px 155px 56px;

      .c-module {
        flex-direction: column;
        padding-bottom: 66px;

        &:last-child {
          padding-bottom: 0;
        }

        &__name {
          margin-bottom: 12px;
          margin-left: 48px;
          padding-left: 0;
        }

        &__lessons {
          margin-left: 48px;
        }
      }
    }

    &__certificate {
      margin-bottom: 172px;
      padding-top: 53px;
    }

    .certificate {

      &__description {
        margin-right: 78px;
        // padding-top: 124px;
      }
    }
  }

  .contacts-page {

    .c-breadcrumbs {
      margin-bottom: 12px;
    }
  }

  .contacts {

    &__wrapper {
      flex-direction: column-reverse;
    }

    &__description {
      margin-left: 0;
      padding-top: 0px;
    }

    &__block {
      display: flex;
      width: 100%;
      margin-right: 42px;

      &:last-child {
        margin-right: 0;
      }
    }

    &__mail {
      width: 182px;
      margin-right: 42px;
    }

    &__mail-text {
      // width: 208px;
      margin-top: 0;
    }

    &__item {
      margin-right: 42px;
      margin-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }

    &__socials {
      margin-top: 40px;
    }

    &__description {
      margin-bottom: 56px;
    }

    iframe {
      height: 572px;
    }
  }

  .m-feedback {
    flex-direction: column;
    padding: 100px 161px 148px 160px;

    &-bg-2.bg-dots-3 {
      top: 483px;
    }

    &__button {
      height: 54px;
    }

    &__container {
      margin-right: 0;
      margin-bottom: 40px;
    }
  }
}
@media screen and (max-width: 945px) {

  .contacts {

    &__mail {
      width: 215px;
    }
  }

  .main-page,
  .teacher-page {

    .direction {

      &__container {
        display: flex;
        flex-wrap: wrap;

        .scroll-content {
          display: flex;
        }

        .c-profession {
          width: 304px;
          height: 304px;
        }
      }
    }

    .scroll-content {
      // display: flex;
      margin-bottom: 42px;
    }
  }

  .m-courses {

    .cards-4-3-2-1 {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .main {

    .hello-screen {
      flex-direction: column;

      &__title {
        @include text-style-titles-h-2-s-40;
      }

      &__img {
        max-width: 347px;
        margin-right: 0;
      }
    }
  }

  .contacts {

    &__info {
      flex-wrap: wrap;
    }

    &__block {
      margin-right: 0;
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .teacher {

    &__employ {
      flex-direction: column;
    }

    &__description {
      margin-top: 24px;
      margin-left: 0;
    }

    .c-qualification {
      margin-left: 20px;

      &__date {
        display: none;

        &-mb {
          display: block;
        }
      }

      &__info {
        width: 100%;
        padding-bottom: 65px;
        padding-left: 79px;
      }

      &__description {
        margin-top: 8px;
      }
    }
  }

  .courses {

    &__filter-btn {
      margin-right: 45px;
    }
  }

  .course {

    &-teachers__items {
      // grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    }
  }

  .m-feedback {
    padding: 76px 86px 192px;
  }
}

@media screen and (max-width: 769px) {

  .b-cardButton {
    width: 480px;
    max-width: 100%;
  }

  .m-courses {

    &__container {
      padding: 20px;
      overflow: hidden !important;
    }

    .cards-2,
    .cards-3-2,
    .cards-3-2.--hidden {
      display: flex;
      grid-column-gap: 0px;
      grid-row-gap: 0px;
    }

    .cards-2,
    .cards-3-2,
    .cards-3-2.--hidden {

      .c-card,
      .c-card-hidden {
        width: 480px;
      }

      .c-card-hidden {
        margin-right: 23px;
      }
    }
  }

  .searchForm {
    max-width: 85%;
  }

  .teacher {

    &__img {
      max-width: 310px;
    }
  }

  .m-courses__button {
    display: none;
  }

  .main {

    &-bg {

      &-1 {

        &.bg-dots-1 {

          svg {
            left: -55%;
          }
        }
      }

      &-2 {

        &.bg-dots-2 {

          svg {
            top: 950px;
            right: -10%;
          }
        }
      }

      &-3 {

        &.bg-dots-3 {

          svg {
            right: -24%;
          }
        }
      }
    }

    .hello-screen {

      &__counter {
        margin-right: 75px;
      }

      &__container {
        padding-bottom: 139px;
      }
    }
  }

  .m-courses {

    .cards-3-2 {
      display: flex;
      flex-direction: row;
      height: 618px;

      &.--hidden {
        display: flex;
        flex-direction: row;
        height: 618px !important;
        margin-top: 0 !important;
        overflow: visible !important;
      }
    }
  }

  .c-titleHead {

    &__container {
      flex-direction: column;
      align-items: flex-start;
    }

    &__text {
      margin-bottom: 14px;
    }
  }

  .b-scrollTopButton {
    width: 32px;
    height: 68px;

    svg {
      width: 28px;
      height: 28px;
    }
  }

  .about {

    &__docs {

      &-container {
        flex-wrap: wrap;

        img {
          width: 45%;
          margin-bottom: 32px;
        }
      }
    }
  }

  .m-faqs-container {
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  }

  .m-feedback {

    .b-input {

      &__error-text {
        position: relative;
        overflow: hidden;
        @include text-style-others-s-12-w-400;

        &.--active {
          padding-top: 8px;
        }
      }
    }
  }

  .m-checks {

    &__container {
      grid-template-columns: repeat(auto-fit, minmax(192px, 1fr));
      row-gap: 27px;

      // .b-checkItem{
      //   display: flex;
      //   flex-direction: row;

      //   &__text{
      //     margin-top: 0;
      //     margin-left: 18px;
      //   }
      // }
    }
  }

  .course {

    .m-checks{

      &__container{
        grid-template-columns: repeat(auto-fit, minmax(201px, 1fr));
      }
    }

    .certificate {

      &__img {
        // margin: 0 auto;
        max-width: 275px;
      }

      &__description {
        margin-right: 30px;
        // padding-top: 61px;
      }
    }

    &__certificate {
      // flex-direction: column-reverse;
      margin-bottom: 145px;
      padding-top: 42px;
    }
  }

  .footer {

    &__contacts {

      &-block {
        justify-content: space-between;
        max-width: 100%;
        margin-right: 58px;
      }
    }

    &__top {
      width: 100%;
    }

    &__logos {
      margin-bottom: 64px;
    }

    &__list {
      margin-right: 47px;
    }

    &__bottom {

      &-link {
        margin-right: 18px;
      }

      &-wrapper {
        flex-direction: column;
      }
    }
  }
}

@media screen and (max-width: 650px) {

  .main {

    &-bg {

      &-1 {

        &.bg-dots-1 {

          svg {
            left: -70%;
          }
        }
      }

      &-2 {

        &.bg-dots-2 {

          svg {
            top: 1112px;
            right: -47%;
          }
        }
      }

      &-3 {

        &.bg-dots-3 {

          svg {
            right: -30%;
          }
        }
      }
    }
  }

  .m-courses {

    .cards-4-3-2-1 {
      display: flex;
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      flex-direction: column;
    }
  }

  .header {

    &__phone {
      display: none;
    }
  }

  .privacy-policy {

    h1 {
      @include text-style-titles-h-2-s-40;
    }
  }

  .about {

    h1 {
      @include text-style-titles-h-2-s-40;
    }

    img {
      //height: 328px;
    }

    .priority-img {
      height: 254px;
    }

    &__docs {

      &-container {

        .scrollbar-thumb {
          height: 2px;
          background-color: $color-primary-500;
        }

        .scrollbar-track {
          height: 2px;
          background-color: $color-achromatic-gray-100;
        }

        .scroll-content {
          display: flex;
        }

        img {
          width: 67%;
          height: auto;
          margin-bottom: 40px;
        }
      }
    }
  }

  .contacts {

    iframe {
      height: 440px;
    }
  }

  .course {

    &__certificate{
      flex-direction: column-reverse;
      margin-bottom: 153px;
      padding-top: 18px;
    }

    &__teachers {
      .c-teacherCard {
        max-width: 100%;
      }
    }

    .module {
      margin-bottom: 90px;
      padding: 64px 56px 155px 48px;

      &__title {
        @include text-style-titles-h-6-s-18;
        margin-bottom: 6px;
      }

      &__link {
        @include text-style-others-s-12-w-400;
      }

      &__header {
        margin-bottom: 20px;
      }

      .c-module {
        padding-bottom: 16px;
        border-width: 0px;

        &::before {
          display: none;
        }

        &__name {
          margin-left: 0px;
          padding-left: 0;

          &-title {
            @include text-style-others-s-12-w-600;
            margin-bottom: 4px;
          }

          &-subtitle {
            @include text-style-others-s-14-w-600;
          }
        }

        &__lessons {
          margin-left: 0px;

          &-title {
            @include text-style-others-s-12-w-600;
            margin-bottom: 8px;
          }

          &-list {

            .b-listItem {
              @include text-style-texts-s-12-w-400;
              color: $color-achromatic-black;

              &::before {
                content: url('../assets/images/decorations/dot-black-xs.svg');
              }
            }
          }
        }
      }
    }
  }

  .course {

    .certificate {
      margin-bottom: 145px;

      &__description {
        margin-top: 46px;
        margin-right: 0;
        // padding-top: 0;
      }
    }
  }

  .teacher {

    &__name {
      @include text-style-titles-h-2-s-36;
    }
  }

  .m-feedback {
    padding: 76px 51px 192px;

    &__title {
      @include text-style-titles-h-3-s-30;
    }

    &__text {
      margin-top: 10px;
    }

    &__info {
      flex-direction: column;
    }

    &__confindencial {
      width: 100%;
      margin-bottom: 40px;
    }

    &-bg-2.bg-dots-3 {
      top: 538px;
    }

    .m-feedback__input:last-child {
      margin-bottom: 0px;
    }

    &__input {
      margin-bottom: 24px;

      .b-input {

        input {
          padding-bottom: 10px;

          &::placeholder {
            @include text-style-others-s-12-w-400;
          }
        }
      }
    }
  }

  .courses {

    .cards {
      grid-column-gap: 0px;
      grid-template-columns: 1fr;
    }

    &__title {
      @include text-style-titles-h-2-s-40;
      margin-bottom: 48px;
    }

    &__content {
      margin-top: 32px;
    }
  }

  .teachers {

    &__title {
      @include text-style-titles-h-2-s-40;
      margin-bottom: 44px;
    }
  }

  .c-teacherCard {
    display: flex;
    max-height: 100%;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0px;
    }

    &__container {
      flex: 70%;
      padding: 18px 20px 64px;
    }

    &__title {
      @include text-style-titles-h-6-s-18;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__text {
      margin-top: 8px;
      @include text-style-others-s-14-w-400;
    }
  }

  .teachers,
  .courses {

    &__categories {
      margin: 0;
    }

    .b-buttonsCategories {
      display: none;
    }

    &__accordion {
      display: block;
    }

    .accordion {

      .scrollbar-thumb {
        width: 4px;
        margin-left: -14px;
        background-color: $color-achromatic-gray-200;
      }

      .scrollbar-track {
        width: 4px;
        margin-left: -14px;
        background-color: transparent;
      }

      .accordion__gradient {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 106px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
      }

      &__item {

        ul {
          position: relative;

          li {
            margin-bottom: 20px;
          }
        }

        &.--active {

          .accordion__title {
            margin-bottom: 0;
          }

          .accordion__block {
            // z-index: 3;
            max-height: 336px;
            margin-top: 14px;
            opacity: 1;
          }
        }
      }

      &__title {
        padding-top: 23px;
        padding-bottom: 23px;
        color: $color-primary-500;
        border-bottom: 1px solid rgba(33, 138, 61, 0.2);
      }

      &__block {
        position: absolute;
        width: 90%;
        // height: 236px;
        padding: 16px 38px 16px 26px;
        overflow: hidden;
        background: $color-achromatic-white;
        border-radius: 12px;
        opacity: 0;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
      }
    }
  }

  .footer {

    .accordion {

      &__item {
        padding: 22px 0;
      }
    }

    &__accordion {
      display: block;
    }

    &__lists {
      display: none;
    }

    &__contacts {
      justify-content: space-between;

      &-block {
        max-width: 236px;
        margin-right: 37px;
      }
    }

    &__logos {
      flex-direction: column;
      align-items: flex-start;
    }

    &__logo {
      margin-bottom: 44px;
    }
  }
}

@media screen and (max-width: 540px) {

  .b-status {

    &__condition {

      .b-status__close,
      .b-status__start {

        &::before {
          width: 6px;
          height: 6px;
          margin-right: 8px;
        }
      }
    }
  }

  .sendStatus {

    &.--active-send {
      bottom: 0;
    }

    &__wrapper {
      width: 100%;
      border-radius: 0px;
    }

    &__icon {

      svg {
        width: 32px;
        height: 32px;
      }
    }

    &__text {
      @include text-style-others-s-12-w-500;
    }

    .b-iconButton {
      width: 28px;
      height: 28px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .b-cardButton {
    width: 292px;
    height: 386px;
    padding: 38px 20px 22px 32px;

    &__text {
      @include text-style-titles-h-4-s-24;
    }

    svg {
      width: 84px;
      height: 84px;
    }
  }

  .m-courses {

    &__container {
      height: 400px;
      padding: 0 8px;
    }

    .cards-2,
    .cards-3-2,
    .cards-3-2.--hidden {
      height: 386px !important;

      .c-card,
      .c-card-hidden {
        width: 292px;
        height: 386px;

        &__img {

          img {
            height: 161px;
          }
        }
      }
    }
  }

  .searchForm {
    @include text-style-others-s-14-w-400;
    padding-top: 16px;

    a {
      margin-bottom: 20px;
    }

    &__content {

      .scrollbar-thumb,
      .scrollbar-track {
        width: 3px;
      }
    }
  }

  .m-courses {

    .scrollbar-thumb {
      height: 2px;
      background-color: $color-primary-500;
    }

    .scrollbar-track {
      height: 2px;
      background-color: $color-achromatic-gray-100;
    }

    .cards-2,
    .cards-3-2,
    .cards-3-2.--hidden {

      .c-card,
      .c-card-hidden {
        width: 292px;

        &__wrapper {
          padding: 14px 14px 20px 16px;
        }

        &__title {
          @include text-style-others-s-14-w-600;
        }

        &__info {
          margin-top: 20px;
        }

        .b-status {
          @include text-style-others-s-12-w-400;
        }

        &__price {
          @include text-style-others-s-14-w-400;
          margin-top: 18px;
        }

        &__time {
          @include text-style-others-s-14-w-400;
          margin-top: 6px;
        }

        &__tags {
          @include text-style-others-s-12-w-400;
          margin-top: 18px;
        }
      }
    }

    .cards-4-3-2-1 {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .c-breadcrumbs {

    &__item {

      a {
        @include text-style-others-s-12-w-400;
      }

      &:not(:first-child)::before {
        top: 6px;
        width: 3px;
        height: 3px;
      }
    }
  }

  .header {

    &__bottom {

      &-link {
        margin-right: 24px;

        a {
          @include text-style-others-s-12-w-500;
        }
      }
    }

    .search {

      &__wrap {
        margin: 8px 0;
        padding: 0 12px 0 17px;
      }

      &__icon {

        svg {
          width: 20px;
          height: 20px;
        }
      }

      &__input {
        @include text-style-others-s-14-w-500;

        &::placeholder {
          @include text-style-others-s-14-w-500;
        }
      }

      .b-iconButton {
        width: 32px;
        height: 32px;

        svg {
          width: 22px;
          height: 22px;
        }
      }
    }

    .menu-mobile {

      &__logo {
        margin-bottom: 62px;

        svg {
          width: 167px;
          height: 34px;
        }
      }
    }
  }

  .main {

    .types__container{

      .c-courseType{
        margin-bottom: 12px;
      }
    }

    .direction {

      &__container {

        .scroll-content {
          margin-bottom: 18px;
          column-gap: 10px;
        }

        .c-profession {
          width: 200px;
          height: 200px;

          &__title {
            @include text-style-others-s-14-w-600;
            margin-bottom: 4px;
          }

          &__text {
            @include text-style-others-s-12-w-400;
          }
        }
      }
    }

    &-bg {

      &-1 {

        &.bg-dots-1 {

          svg {
            top: 0;
            left: -44%;
            width: 309px;
          }
        }
      }

      &-2 {

        &.bg-dots-2 {

          svg {
            // top: 732px;
            top: 431px;
            right: -28%;
            width: 309px;
          }
        }
      }

      &-3 {

        &.bg-dots-3 {

          svg {
            top: 0;
            right: -30%;
            height: 309px;
          }
        }
      }
    }

    .hello-screen {
      flex-direction: column;
      margin-bottom: 0;

      &__container {
        padding-top: 22px;
        padding-bottom: 57px;
      }

      &__title {
        @include text-style-titles-h-6-s-18;
        margin-bottom: 10px;
      }

      &__text {
        @include text-style-texts-s-12-w-400;
        margin-bottom: 18px;
      }

      &__counter {
        margin-right: 42px;

        .b-counter {

          &__number {
            @include text-style-titles-h-4-s-24;
            margin-bottom: 3px;
          }

          &__text {
            @include text-style-texts-s-12-w-400;
          }
        }
      }

      &__img {
        margin-right: 0;

        img {
          width: 172px;
        }
      }
    }

    &__content {
      margin-bottom: 64px;
    }

    .types {

      &__title {
        @include text-style-titles-h-6-s-18;
      }

      &__container {
        margin-top: 22px;
      }

      .c-courseType {
        flex-direction: column;
        align-items: flex-start;
        padding: 18px 26px 26px 20px;

        &__img {
          margin-right: 0;
          margin-bottom: 16px;

          svg {
            width: 46px;
            height: 46px;
          }
        }

        &__title {
          @include text-style-others-s-14-w-600;
          margin-bottom: 4px;
        }

        &__text {
          @include text-style-texts-s-12-w-400;
        }
      }
    }
  }

  .c-titleHead {
    margin-bottom: 18px;

    &__title {
      @include text-style-titles-h-6-s-18;
      margin-bottom: 6px;
    }

    &__text {
      @include text-style-texts-s-12-w-400;
      margin-bottom: 6px;
    }

    &__link {
      @include text-style-texts-s-12-w-400;
    }
  }

  .header {

    &__logo-nav {
      width: 100%;
    }

    &__logo {
      margin: 0 auto;

      svg {
        width: 146px;
        height: 28px;
      }
    }

    &__search {

      .b-iconButton {

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    &__mobile-open {
      margin-right: 0px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .container {
    padding-right: 14px;
    padding-left: 14px;
  }

  .privacy-policy {
    margin: 0 0 104px;

    p {
      @include text-style-texts-s-12-w-400;
      margin-bottom: 6px;
    }

    ul {
      @include text-style-texts-s-12-w-400;

      li {
        margin-bottom: 4px;

        &::before {
          content: url('../assets/images/decorations/dot-black-xs.svg');
          width: 3px;
          height: 3px;
          margin-right: 6px;
        }
      }
    }

    h1 {
      @include text-style-titles-h-6-s-18;
    }

    h2 {
      @include text-style-others-s-16-w-600;
    }
  }

  .about {

    p {
      @include text-style-texts-s-12-w-400;
      margin-bottom: 6px;
    }

    ul {
      @include text-style-texts-s-12-w-400;

      li {
        margin-bottom: 4px;

        &::before {
          content: url('../assets/images/decorations/dot-black-xs.svg');
          width: 3px;
          height: 3px;
          margin-right: 6px;
        }
      }
    }

    h1 {
      @include text-style-titles-h-6-s-18;
    }

    .b-link {
      @include text-style-others-s-10-w-400;
    }

    .priority-img {
      height: 165px;
    }

    &__content {

      h2 {
        @include text-style-others-s-16-w-600;
        width: auto;
        margin-bottom: 10px;
      }

      //img {
      //  height: 165px;
      //  margin-top: 24px;
      //  margin-bottom: 18px;
      //  border-radius: 10px;
      //}
    }

    &__subdivisions {

      h3 {
        margin: 20px 0 12px;
        @include text-style-others-s-14-w-600;
      }
    }

    &__dpo {

      h2 {
        margin: 24px 0 10px;
      }

      h3 {
        margin: 16px 0 10px;
        @include text-style-others-s-14-w-600;
      }
    }

    &__docs {
      margin: 64px 0;

      h2 {
        @include text-style-titles-h-6-s-18;
        margin-bottom: 16px;
      }

      &-container {

        img {
          max-width: 174px;
          margin-bottom: 16px;
          border-radius: 10px;
        }
      }
    }

    .c-subdivision {
      margin-bottom: 10px;

      &__title {
        @include text-style-others-s-12-w-600;
        margin-bottom: 6px;
      }

      &__text {
        @include text-style-texts-s-12-w-400;
        margin-bottom: 6px;
      }
    }
  }

  .m-faqs {
    margin: 64px 0 104px;

    &-title {
      margin-bottom: 20px;
      @include text-style-titles-h-6-s-18;
    }

    &-container {
      grid-template-columns: repeat(auto-fit, minmax(292px, 1fr));
      row-gap: 16px;
    }

    .b-faq {

      &__container {
        margin-left: 16px;
      }

      &__question {
        @include text-style-others-s-14-w-600;
      }

      &__answer {
        @include text-style-texts-s-12-w-400;
        margin-top: 8px;
      }

      &__img {

        svg {
          width: 34px;
          height: 34px;
        }
      }
    }
  }

  .contacts-page {

    .c-breadcrumbs {
      margin-bottom: 10px;
    }
  }

  .contacts {

    &__title {
      @include text-style-titles-h-6-s-18;
    }

    &__subtitle {
      @include text-style-texts-s-12-w-400;
      margin: 8px 0 24px;
    }

    &__number {
      @include text-style-others-s-14-w-600;
      width: auto;

      &-text {
        @include text-style-others-s-12-w-400;
        margin-top: 4px;
      }
    }

    &__mail {
      width: auto;
      margin-right: 0;
      margin-bottom: 18px;
      @include text-style-others-s-14-w-600;

      &-text {
        @include text-style-others-s-14-w-400;
      }
    }

    &__block {
      margin-bottom: 18px;

      &:last-child {
        flex-direction: column;
      }
    }

    &__item {
      margin-right: 20px;
    }

    iframe {
      height: 350px;
    }

    &__map {
      border-radius: 10px;
    }

    &__socials {
      margin-top: 32px;

      &-icon {
        margin-right: 18px;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    &__description {
      margin-bottom: 40px;
    }

    &__feedback {
      margin: 64px 0 106px;
    }
  }

  .teachers {

    &__button {

      .b-button {
        @include text-style-others-s-12-w-500;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    &__title {
      @include text-style-titles-h-6-s-18;
      margin-bottom: 32px;
    }

    &__cards {
      margin-top: 26px;
    }
  }

  .c-teacherCard {
    flex-direction: column;
    // max-width: 292px;
    margin-bottom: 22px;

    &__container {
      padding: 14px 16px 40px;
    }

    &__title {
      @include text-style-others-s-16-w-600;
    }

    &__text {
      @include text-style-others-s-12-w-400;
      margin-top: 6px;
    }
  }

  .teacher {

    &__employ {
      margin-bottom: 66px;
    }

    &__description {
      margin-top: 14px;
    }

    &__block-info {
      margin-top: 30px;

      .b-listItem {
        @include text-style-texts-s-12-w-400;
        margin-bottom: 4px;

        &::before {
          content: url('../assets/images/decorations/dot-black-xs.svg');
          margin-right: 6px;
        }
      }
    }

    &__block {

      &-title {
        @include text-style-others-s-14-w-600;
        margin-bottom: 8px;
      }
    }

    &__position {
      margin-top: 8px;
      @include text-style-others-s-12-w-400;
    }

    &__img {
      max-width: 212px;
      border-radius: 10px;
    }

    &__name {
      @include text-style-titles-h-6-s-18;
    }
  }

  .teachers,
  .courses {

    .accordion {

      &__title {
        @include text-style-others-s-14-w-600;
        padding: 12px 0;
      }

      .b-iconButton {
        width: 28px;
        height: 28px;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      ul {

        li {
          @include text-style-others-s-14-w-400;
          margin-bottom: 14px;
        }
      }
    }
  }

  .courses {

    &__content {
      margin-top: 24px;
    }

    &__wrapper {
      margin-bottom: 24px;
    }

    .cards {

      .c-card {
        // width: 292px;
        // height: 386px;
        border-radius: 10px;

        &__img {

          img {
            height: auto;
          }
        }

        &__wrapper {
          padding: 14px 14px 20px 16px;
        }

        &__title {
          @include text-style-others-s-14-w-600;
        }

        &__info {
          margin-top: 20px;
        }

        &__price {
          @include text-style-others-s-14-w-500;
          margin-top: 18px;
        }

        .b-status {
          @include text-style-others-s-12-w-400;

          &__start,
          &__close {

            &::before {
              width: 6px;
              height: 6px;
              margin-right: 8px;
            }
          }
        }

        &__price {
          @include text-style-others-s-14-w-500;
          margin-top: 18px;
        }

        &__time {
          @include text-style-others-s-14-w-400;
          margin-top: 6px;
        }

        &__tags {
          @include text-style-others-s-12-w-400;
          margin-top: 18px;
        }
      }
    }

    &__title {
      @include text-style-titles-h-6-s-18;
      margin-bottom: 32px;
    }

    &__filter-btn {
      @include text-style-others-s-12-w-500;
      flex: 85%;
      margin-right: 0;

      .b-button {
        @include text-style-others-s-12-w-500;
        padding: 10px 27px 10px 24px;

        svg {
          width: 17px;
          height: 17px;
        }
      }
    }

    &__wrapper {
      align-items: center;
    }

    .search__container {
      display: none;
    }

    .search {

      &__mobile {
        display: flex;
        width: 22px;
        height: 22px;

        svg {
          position: relative;

          path {
            fill: $color-primary-500;
          }
        }
      }

      max-width: 20px;

      &__input {

        input {
          border-bottom: 0px solid $color-achromatic-gray-500;
        }
      }
    }

    &__button {

      .b-button {
        @include text-style-others-s-12-w-500;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }

  .m-checks {

    &__container {
      grid-template-columns: repeat(auto-fit, minmax(282px, 1fr));
    }
  }

  input {
    @include text-style-others-s-12-w-400;
  }

  .m-feedback {
    padding: 30px 22px 66px;
    border-radius: 10px;

    .b-input__error-text {
      @include text-style-others-s-12-w-400;

      &.--active {
        padding-top: 4px;
      }
    }

    &__title {
      @include text-style-titles-h-6-s-18;
    }

    &__container {
      margin-bottom: 32px;
    }

    &__text {
      margin-top: 6px;
      @include text-style-texts-s-12-w-400;
    }

    &__confindencial {
      @include text-style-others-s-10-w-400;
      margin-bottom: 31px;

      .b-link {
        @include text-style-others-s-10-w-400;
      }
    }

    &__button {
      @include text-style-others-s-12-w-500;
      height: auto;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &-bg-1.bg-dots-3 {
      display: none;
    }

    &-bg-2.bg-dots-3 {
      top: 320px;
    }
  }

  .course {

    &__modules-bg-2.bg-dots-3{
      bottom: -355px;
      left: -2px;

      svg{
        width: 149px;
      }
    }

    .course-info {
      margin-bottom: 32px;

      &__title {
        @include text-style-titles-h-6-s-18;
        margin-bottom: 17px;
      }

      &__img {
        max-height: 186px;
        margin-bottom: 20px;
        border-radius: 10px;

        &-text {
          bottom: 21px;
          left: 24px;

          svg {
            max-width: 142px;
          }
        }
      }

      &__price {
        @include text-style-others-s-14-w-500;
        margin-top: 17px;
      }

      &__time {
        margin-top: 10px;
        @include text-style-others-s-14-w-400;
      }

      &__register {
        margin-top: 26px;
        padding: 10px 32px;
        @include text-style-others-s-12-w-500;
      }

      @media screen and (min-width: 320px) and (max-width: 575px) {
        &__register {
          width: 100%;
        }
      }

      .b-status__condition {
        @include text-style-others-s-12-w-400;
        margin-bottom: 5px;

        .b-status {
          margin-bottom: 0;

          &__start {

            &::before {
              width: 6px;
              height: 6px;
            }
          }
        }
      }
    }

    &__about {
      margin-bottom: 76px;

      P {
        @include text-style-texts-s-12-w-400;
      }
    }

    &__checks {
      margin-bottom: 90px;

      .m-checks {

        &__title {
          @include text-style-titles-h-6-s-18;
          margin-bottom: 28px;
        }

        .b-checkItem {
          display: flex;
          flex-direction: row;
          align-items: center;

          &__img {

            svg {
              width: 24px;
              height: 42px;
            }
          }

          &__text {
            margin-top: 0;
            margin-left: 18px;
            @include text-style-texts-s-12-w-400;
          }
        }
      }
    }

    &__certificate{
      padding-top: 0;
    }

    .certificate {
      margin-bottom: 90px;

      &__img {
        max-width: 180px;
        border-radius: 10px;
      }

      &__description {
        margin-top: 30px;
      }

      &__title {
        @include text-style-titles-h-6-s-18;
        margin-bottom: 8px;
      }

      &__text {
        @include text-style-texts-s-12-w-400;
      }
    }

    &-teachers {

      &__title {
        @include text-style-titles-h-6-s-18;
        margin-bottom: 38px;
      }
    }

    &__module {
      margin-top: 90px;
      border-radius: 10px;
    }

    .module {
      //padding: 28px 24px 90px;
      padding: 28px 24px 24px;
    }
  }

  .teacher {

    &__qualification {
      margin-bottom: 80px;
    }

    .c-qualification {
      margin-left: 14px;

      &__info {
        padding-bottom: 49px;
        padding-left: 44px;
      }

      &__date-mb {
        @include text-style-others-s-14-w-600;
        margin-bottom: 6px;
      }

      &__title {
        @include text-style-titles-h-6-s-18;
      }

      &__description {
        // margin-top: 6px;
        @include text-style-texts-s-12-w-400;
      }

      &__icon {
        left: -21px;
        width: 40px;
        height: 40px;
        padding: 10px;

        &.--arrows,
        &.--sync {
          background-size: 60%;
        }
      }
    }
  }

  .c-teacherCard {
    display: block;
    height: auto;

    &__container {
      padding: 14px 16px 40px;
    }

    &__title {
      @include text-style-others-s-16-w-600;
    }

    &__text {
      margin-top: 6px;
    }
  }

  .footer {

    &__wrapper {
      padding: 48px 14px;
    }

    .accordion {

      &__title {
        @include text-style-others-s-14-w-600;
      }

      &__item {
        padding: 12px 0;

        &.--active {

          .accordion__title {
            margin-bottom: 0;
            margin-bottom: 16px;
          }

          .accordion__block {
            height: 236px;
            margin-top: 0px;
          }
        }
      }

      ul {

        li {
          @include text-style-others-s-14-w-400;
          margin-bottom: 14px;
        }
      }

      .b-iconButton {
        width: 28px;
        height: 28px;
        padding: 0;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    &__top {
      margin-bottom: 40px;
    }

    &__contacts {
      margin-top: 40px;

      &-block {
        margin-right: 30px;
      }
    }

    &__bottom {

      &-wrapper {
        flex-direction: column-reverse;
        padding: 26px 0 0;
      }

      &-link {
        @include text-style-others-s-12-w-400;
        margin-right: 7px;
        margin-bottom: 12px;
      }
    }

    &__copyright {
      margin-top: 34px;
      margin-bottom: 0;
      font-size: 10px;
    }

    &__logo {
      margin-right: 0;
      margin-bottom: 38px;
    }

    &__logos {
      margin-bottom: 28px;

      .logo-kubsau {

        svg {
          width: 214px;
          max-width: 100%;
          height: 46px;
        }
      }

      .priority {

        svg {
          width: 142px;
          max-width: 100%;
          height: 28px;
        }
      }
    }

    &__contacts {

      &-block {
        max-width: 165px;
        margin-bottom: 18px;
      }

      &-number {
        @include text-style-others-s-14-w-600;
        width: auto;
      }

      &-text {
        @include text-style-others-s-12-w-400;
      }

      &-mail {
        @include text-style-others-s-14-w-600;
        margin-top: 0;
      }

      &-address {
        @include text-style-others-s-14-w-400;
        margin-top: 0;
      }
    }

    &__socials {
      margin-top: 37px;
    }

    &__social {

      &-icon {
        margin-right: 18px;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .error {

    &__number {
      @include title-404-mobile;
      margin-bottom: 4px;
    }

    &__text {
      @include descroption-404-mobile;
      margin-bottom: 40px;
    }

    &__btn {
      @include text-style-others-s-12-w-500;
      padding: 10px 32px;
    }
  }
}

@media screen and (max-width: 320px) {

  .m-feedback {

    &-bg-2.bg-dots-3 {
      top: 380px;
      left: -29px;

      svg {
        width: 149px;
        height: 309px;
      }
    }
  }

  .main {

    &-bg {

      &-1 {

        &.bg-dots-1 {

          svg {
            top: 0;
            left: -78%;
            width: 309px;
          }
        }
      }

      &-2 {

        &.bg-dots-2 {

          svg {
            top: 480px;
            right: -47%;
            width: 309px;
          }
        }
      }

      &-3 {

        &.bg-dots-3 {

          svg {
            top: 0;
            right: -52%;
            height: 309px;
          }
        }
      }
    }
  }
}
