/* ================================ Fonts ================================ */
@font-face {
  font-family: Montserrat;
  font-weight: 400;
  font-style: normal;
  src: url('../assets/fonts/Montserrat-Regular.woff') format('woff'), url('../assets/fonts/Montserrat-Regular.ttf') format('truetype'); // Safari, Android, iOS
}

@font-face {
  font-family: Montserrat;
  font-weight: 500;
  font-style: normal;
  src: url('../assets/fonts/Montserrat-Medium.woff') format('woff'), url('../assets/fonts/Montserrat-Medium.ttf') format('truetype'); // Safari, Android, iOS
}

@font-face {
  font-family: Montserrat;
  font-weight: 600;
  font-style: normal;
  src: url('../assets/fonts/Montserrat-SemiBold.woff') format('woff'), url('../assets/fonts/Montserrat-SemiBold.ttf') format('truetype'); // Safari, Android, iOS
}
