/* ================================ Styles ================================ */
$max-width-container: 1512px;

$color-primary-10: #f1fcf4;
$color-primary-100: #d6f0dd;
$color-primary-200: #a6d0b1;
$color-primary-300: #82cb96;
$color-primary-400: #34b456;
$color-primary-500: #218a3d;
$color-primary-800: #004a14;
$color-achromatic-black: #000000;
$color-achromatic-white: #ffffff;
$color-achromatic-gray-100: #f0f0f0;
$color-achromatic-gray-200: #cccccc;
$color-achromatic-gray-500: #949494;
$color-achromatic-gray-700: #5a5a5a;
$color-secondary-red-200: #ff9dae;
$color-secondary-red-400: #e8415e;
$color-secondary-red-500: #ea0029;
$color-secondary-blue-300: #88a4ff;
$color-secondary-blue-350: #6a87e1;
$color-secondary-blue-500: #001b71;
