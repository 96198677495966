// html {
//   scroll-behavior: smooth;
// }

.about__docs-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

.how__postupit-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  margin-bottom: 30px;
}
.post-card {
  display: flex;
  flex-direction: column;
  border-top: 2px solid #cacaca;
}
.step {
  font-family: Montserrat;
  font-size: 45px;
  font-weight: 600;
  font-style: normal;
  line-height: 144%;
  letter-spacing: 0.02em;
  text-decoration: none;
  margin-bottom: 20px;
  color: green;
}
.card-content {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  //font-family: Montserrat;
  //font-size: 15px;
  //line-height: 144%;
  //font-weight: 600;
  font-style: normal;
  letter-spacing: 0.02em;
  text-decoration: none;
  margin-bottom: 20px;

  font-size: 20px;
  line-height: 23px;
  color: #000000;
  font-family: 'Montserrat';
  font-weight: 400;
}
.card-content.open {
  -webkit-box-orient: unset;
}
.how__postupit h1 {
  margin-bottom: 50px;
}
@media screen  and  (max-width: 575px) {
  .how__postupit-grid {
    display: block;
    padding: 0px 15px 0px 15px;
  }
  .card-content {
    font-size: 12px;
  }
  .step {
    margin-bottom: 0px;
    font-size: 40px;
  }
}

.modern-page-previous, .modern-page-next {
  padding: 10px;
  border-radius: 10px;
  background-color: #A8D7AB;
  transition: 0.3s background-color, 0.3s color;
  color: #37383B;
  font-family: 'Montserrat';
}
.modern-page-number, .modern-page-current {
  font-family: 'Montserrat';
  color: white;
  background-color: #26822f;
  border-radius: 10px;
  padding: 10px 15px;
  margin-right: 5px;
}
.modern-page-navigation a {
  font-family: 'Montserrat';
  border-radius: 10px;
  padding: 10px 15px;
  margin-right: 5px;
  color: black;
  transition: .3s;
}
.modern-page-navigation a:hover {
  background-color: #d6d6d6;
  transition: .3s;
}
.modern-page-navigation {
  padding: 20px 0;
}
.modern-page-navigation, .modern-page-previous, .modern-page-navigation, .modern-page-number, .modern-page-navigation, .modern-page-dots, .modern-page-navigation, .modern-page-next {
  text-decoration: none;
  color: black;
}
.modern-page-arrows {
  display: none;
}

@media screen and (max-width: 575px) {

  .modern-page-navigation {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
  }

  .modern-page-navigation .desktop {
    display: none;
  }
  .modern-page-arrows {
    display: flex;
  }
  .modern-page-navigation .mobile {
    display: block;
  }
  .modern-page-navigation a {
    font-size: 12px;
    padding: 5px 10px;
  }
  .modern-page-number, .modern-page-current {
    font-size: 12px;
    padding: 5px 10px;
  }
}

.course-feedback_title h1 {
  margin-bottom: 30px;
}
.feedback {
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  padding: 45px 45px 20px 45px;
  border-radius: 33px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.feedback-swiper {
  padding: 10px!important;
}

.swiper-wrapper {
  margin-bottom: 20px;
  position: relative;
}

//.feedback-swiper::before {
//  content: '';
//  position: absolute;
//  right: 0;
//  top: 0;
//  height: calc(100% - 40px);
//  width: 3px;
//  background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgb(24 102 23), rgba(0, 0, 0, 0));
//  z-index: 2;
//}
//.feedback-swiper::after {
//  content: '';
//  position: absolute;
//  left: 0;
//  top: 0;
//  height: calc(100% - 40px);
//  width: 3px;
//  background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgb(24 102 23), rgba(0, 0, 0, 0));
//  z-index: 2;
//}
.feedback-title {
  margin-bottom: 20px;
  text-align: start;
}
.feedback-title a {
  font-size: 16px;
  color: #27832F;
  line-height: 120%;
  font-family: Montserrat;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.02em;
  text-decoration: none;
  display: -webkit-box;
  -webkit-line-clamp: 2; // количество строк
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  &.show {
    -webkit-line-clamp: 7; // количество строк
  }

  &.active {
    -webkit-line-clamp: 7; // количество строк
  }
}
.feedback-text {
  overflow: hidden;
  hyphens: auto;
  text-align: start;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 141%;
  letter-spacing: 0.02em;
  text-decoration: none;
  margin-bottom: 35px;
}
.feedback-name {
  text-align: start;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  line-height: 144%;
  letter-spacing: 0.02em;
  text-decoration: none;
  margin-bottom: 20px;
}
.photo a {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  line-height: 144%;
  letter-spacing: 0.02em;
  text-decoration: none;
  margin-bottom: 20px;
  color: green;
  cursor: pointer;
}
.video a {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  line-height: 144%;
  letter-spacing: 0.02em;
  text-decoration: none;
  margin-bottom: 20px;
  color: green;
  cursor: pointer;
}
.pop-up__over {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999999;
  background-color: rgba(0, 0, 0, .3);
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: opacity .4s, visibility .4s;
}
.pop-up__over.open {
  visibility: visible;
  opacity: 1;
}
.photo-pop-up {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.pop-up__head {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.pop-up-cross {
  //transform: translate(25px, -5px);
}
.photo-pop-up__wrapper img,  .photo-pop-up__wrapper video{
  max-width: 700px;
  border-radius: 33px;
}
.feedback-nav {
  position: absolute;
  bottom: 20px;
  display: flex;
  gap: 15px;
}
@media screen and (max-width: 575px) {
  .photo-pop-up__wrapper img,  .photo-pop-up__wrapper video{
    max-width: 320px;
  }
}

.about__dpo {
  margin-bottom: 50px;
}

.courses-btns {
  display:flex;
  gap: 20px
}

@media screen and (min-width: 320px) and (max-width: 575px) {
  .courses-btns {
    gap: 0;
  }
}

.cards-3-2 {
  display: flex;
}
.interest__courses {
  //margin-bottom: 140px;
  //margin-bottom: 100px;
  margin-bottom: 70px;
}
.interest__courses h2 {
  margin-bottom: 20px;
}
.c-card__wrapper {
  padding: 20px;
}

.payment {
  margin-bottom: 70px;
}
.payment__card {
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  padding: 20px 50px 20px 50px;
  border-radius: 33px;
}
.payment__wrap {
  display: flex;
  flex-direction: column;
}
.payment__title {
  margin-bottom: 20px;
}
.payment__cont {
  border-top: 2px solid rgba(153, 153, 153, 0.27);
  display: flex;
  padding-top: 15px;
  gap: 20px;
  justify-content: space-between;
}
.payment__conditions {
  //border-top: 2px solid rgba(153, 153, 153, 0.27);
  display: flex;
  flex-direction: column;
  max-width: 461px;
}
.payment__cond-card {
  padding: 15px 0 0 70px;
  flex-direction: column;
  display: flex;
}
.money__cond {
  font-family: Arial;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  line-height: 146%;
  text-decoration: none;
  margin-bottom: 12px;
}
.money {
  font-family: Arial;
  font-size: 40px;
  font-weight: 600;
  font-style: normal;
  line-height: 146%;
  text-decoration: none;
  margin-bottom: 12px;
  color: #27832F;
}

.prepods__title {
  margin-bottom: 20px;
}
.prepods-wrap {
  display: flex;
  gap: 30px;
}
.swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  display: block;

  &:not(.swiper-initialized) .swiper-slide {
    max-width: 360px;
  }
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  transition-timing-function: var(--swiper-wrapper-transition-timing-function,initial);
  box-sizing: content-box;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
  transition-property: transform;

  &__img {
    display: block;
    width: 250px;
    border-radius: 50%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;

    &:before {
      content: "";
      padding-top: 100%;
      float: left;
    }
  }
}

.swiper-p {
  margin-bottom: 10px;
  font-family: Arial;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  line-height: 146%;
  text-decoration: none;
}
.swiper-button-prev, .swiper-button-next {
  z-index: 0;
  display: flex;
  /*flex-direction: row;*/
  align-items: center;
  justify-content: center;
  padding: 10px;
  top: calc(50% - 0px);
  background: #d6f0dd;
  width: 50px;
  height: 30px;
  cursor: pointer;
  right: -95px;
  transition: box-shadow .3s;
}
.navigation {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.swiper-button-prev:hover, .swiper-button-next:hover {
  background: green;
  transition: .3s;
}
.swiper-button-prev svg, .swiper-button-next svg {
  fill: green;
  transition: .3s;
}
.swiper-button-prev:hover svg, .swiper-button-next:hover svg {
  fill: #FFFFFF;
  transition: .3s;
}
.swiper-button-prev svg {
  transform: rotate(180deg);
}
.swiper-button-prev {
  border-radius: 68px 0px 0px 68px;
}
.swiper-button-next {
  border-radius: 0px 68px 68px 0px;
}

.ed-form {
  //margin-bottom: 140px;
  //margin-bottom: 100px;
  margin-bottom: 70px;
}

@media screen and (min-width: 320px) and (max-width: 575px) {

  .ed-form {
    margin-bottom: 50px;
  }
  .course__modules {
    margin-top: 50px;
  }
}

.table-stroke {
  width: 100%;
  padding: 10px 20px 10px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-top: 2px solid rgba(153, 153, 153, 0.27);
}
.left-stroke {
  width: 40%;
  padding: 15px;
  font-family: Arial;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  line-height: 146%;
  text-decoration: none;
}
.right-stroke {
  width: 60%;
  font-family: Arial;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  line-height: 146%;
  text-decoration: none;
}

@media screen and (min-width: 320px) and (max-width: 575px) {
  .left-stroke {
    font-size: 18px;
  }
  .right-stroke {
    font-size: 12px;
  }
}

.ed-form_title {
  margin-bottom: 30px;
}

@media screen and (min-width: 320px) and (max-width: 575px) {
  .about__docs-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .table-stroke {
    flex-direction: column;
  }
  .left-stroke {
    width: unset;
    text-align: center;
  }
}

.faqs {
  padding: 0 100px 0 0;
}
.m-faqs-container {
  position: relative;
  background-color: #fff;
  cursor: pointer;
  padding: 20px 0px 20px 0px;
  border-top: 2px solid rgba(153, 153, 153, 0.27);
}
.m-faqs-container::before {
  display: none;
}
.faq-img_title_plus {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.b-faq_cont {
  width: 100%;
}
.plus {
  position: relative;
  width: 20px;
}
.plus span {
  display: block;
  position: absolute;
  content: '';
  width: 20px;
  height: 3px;
  background-color: green;
  border-radius: 5px;
}
.minus-stick {
  transform: rotate(90deg);
  transition: .3s;
}
.minus-stick.active {
  transform: rotate(0deg);
  transition: .3s;
}
.faq-img_title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.faq-img {
  margin-right: 20px;
}
.faq-title {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.02em;
  text-decoration: none;
}
.faq-content {
  opacity: 0;
  overflow: hidden;
  max-height: 0;
  transition: .3s;
  //font-family: Montserrat;
  //font-size: 18px;
  //font-weight: 500;
  font-style: normal;
  letter-spacing: 0.02em;
  text-decoration: none;
  box-sizing: content-box;

  font-size: 20px;
  line-height: 23px;
  color: #000000;
  font-family: 'Montserrat';
  font-weight: 400;
}
.faq-content.open {
  padding-top: 20px;
  opacity: 1;
  transition: .3s;
}

.payment__btn {
  border-radius: 30px;
}
.payment__title {
  margin-bottom: 20px;
}
.payment__cond-card {
  padding: 15px 0 0 30px;
}
.money__cond {
  font-family: Arial;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  line-height: 146%;
  text-decoration: none;
  margin-bottom: 12px;
}
.money {
  font-family: Arial;
  font-size: 40px;
  font-weight: 600;
  font-style: normal;
  line-height: 146%;
  text-decoration: none;
  margin-bottom: 12px;
  color: green;
}
.p-card__txt-wrap {
  max-width: 523px;
  font-family: Arial;
  font-weight: 600;
  font-style: normal;
  line-height: 120%;
  text-decoration: none;
  margin-bottom: 64px;
  font-size: 40px;
  text-align: center;
  //border: 1px solid green;
  color: #27832F;
  border-radius: 30px;
}

body {
  * {

    &::selection {
      color: #ffffff;
      background-color: $color-primary-400;
    }
  }

  &.--no-scroll {
    overflow: hidden;
  }
}

.page {
  padding-top: 24px;
}

.container {
  width: 100%;
  max-width: $max-width-container; // 1512
  margin: 0 auto;
}

h1,
.h1 {
  @include text-style-titles-h-1-s-48;
}

@media screen and (min-width: 320px) and (max-width: 575px) {
  h1, .h1 {
    font-size: 18px;
  }
}

h2,
.h2 {
  @include text-style-titles-h-2-s-40;
}

h3,
.h3 {
  @include text-style-titles-h-3-s-30;
}

h4,
.h4 {
  @include text-style-titles-h-4-s-24;
}

h5,
.h5 {
  @include text-style-titles-h-5-s-20;
}

input {
  @include text-style-others(16px, 400, 21px);
  padding-bottom: 14px;
  border-bottom: 1px solid $color-achromatic-gray-500;

  &::placeholder {
    // @include text-style-others(16px, 400, 21px);
    color: $color-achromatic-black;
  }

  &:focus {
    padding-bottom: 13px;
    border-bottom: 2px solid $color-secondary-blue-350;
  }
}

.layout {
  position: fixed;
  top: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  background-color: $color-achromatic-white;
  opacity: 0;
  transition: 0.3s linear;

  &.--active-popup {
    z-index: 2;
    opacity: 0.7;
    transition: 0.3s linear;
  }
}

.accordion {
  width: 100%;
  overflow: hidden;

  &__title {
    @include text-style-others-s-22-w-600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    transition-duration: 0.3s;
    height: 28px;
  }

  .b-iconButton {
    width: 34px;
    height: 34px;
    padding: 0;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__block {
    overflow-y: hidden;
    transition-duration: 0.3s;
    z-index: 10;
  }

  ul {


    li {
      @include text-style-others-s-18-w-400;
      margin-bottom: 18px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__item {
    display: block;
    color: inherit;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    svg {
      transition: transform 0.3s;
    }

    &.--active {

      .accordion__title {
        margin-bottom: 18px;
      }

      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.swiper {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.gallery-top {
  max-width: 1000px;
  position: relative;

  iframe {
    position: absolute;
    height: 100%;
  }
}

.gallery-top .swiper-slide img, video, iframe {
  width: 100%;
}

.gallery-thumbs {
  box-sizing: border-box;
  padding: 10px 0;
}

.gallery-thumbs .swiper-slide {
  opacity: 0.4;
}
.gallery-thumbs .swiper-slide img {
  width: 100%;
}

.gallery-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}

@media screen and (max-width: 945px) {
  .nov__card {
    flex-direction: column;
  }
}

@media screen and (max-width: 575px) {
  .gallery-thumbs .swiper-slide img {
    height: 100px;
  }
  .nov__card {
    flex-direction: column;
  }
}

.nov__cards {

  .nov__card {
    display: flex;
    padding: 25px 0 25px 0;
    border-top: 2px solid rgba(153, 153, 153, 0.27);
    transition: .3s;
  }
  .nov__card-info:hover {
    background-color: #d4d4d4;
    transition: .3s;
  }

  .nov__card-info {
    display: flex;
    gap: 20px;
    flex-direction: column;
    padding: 15px;
    transition: .3s;
  }

  .nov__card-info span {
    text-align: start;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    line-height: 144%;
    letter-spacing: 0.02em;
    text-decoration: none;
    margin-bottom: 35px;
    color: #37383B;
  }

  .nov__card-title span {
    color: #27832F;
    font-weight: 600;
  }

  .nov__card-text {
    max-width: 1000px;
  }
}

.novosti__detail h1 {
  margin-bottom: 20px;
}

.novosti {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.novosti__text {
  text-align: start;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 144%;
  letter-spacing: 0.02em;
  text-decoration: none;
  margin-bottom: 35px;
  color: #37383B;
}

.main{

  .scrollbar-track-y {
    display: none !important;
  }
}

.sendStatus {
  position: fixed;
  bottom: -100px;
  z-index: 2;
  display: flex;
  justify-content: center;
  width: 100%;
  transition: bottom 0.3s, opacity 0.3s, visibility 0.3s;
  opacity: 0;
  visibility: hidden;

  &.--error {

    .sendStatus__icon {

      svg {

        path {
          fill: $color-secondary-red-400;
        }
      }
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 532px;
    padding: 16px 24px;
    background-color: $color-achromatic-white;
    border-radius: 21px;
    @include effect-style-shadows-blur-100;
  }

  &.--active-send {
    bottom: 20px;
    display: flex;
    opacity: 1;
    visibility: visible;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__text {
    margin-left: 28px;
    @include text-style-others(22px, 500, 30px);
  }

  &__icon {

    svg {
      width: 48px;
      height: 48px;

      path {
        fill: $color-primary-500;
      }
    }
  }

  &__close {

    .b-iconButton {
      width: 48px;
      height: 48px;

      svg {
        width: 33px;
        height: 33px;
      }
    }
  }
}

.toTop {
  position: fixed;
  top: 40vh;
  left: 0;
  width: 100%;
}

.bg-dots {

  &-1 {
    position: absolute;
    z-index: -1;

    svg circle {
      fill: $color-primary-10;

      &.d-179,
      &.d-87,
      &.d-51 {
        animation: dots-green 3s infinite;
      }

      &.d-87 {
        animation-delay: 1.5s;
      }

      &.d-51 {
        animation-delay: 1s;
      }
    }
  }

  &-2 {
    position: absolute;
    z-index: -1;

    svg circle {
      fill: $color-primary-10;

      &.d-25 {
        animation: dots-red 3s infinite;
      }

      &.d-49 {
        animation: dots-green 3s infinite;
        animation-delay: 0.9s;
      }

      &.d-61 {
        animation: dots-green 3s infinite;
        animation-delay: 0.5s;
      }
    }
  }

  &-3 {
    position: absolute;
    z-index: -1;

    svg circle {
      fill: $color-primary-10;

      &.d-61 {
        animation: dots-green 3s infinite;
      }

      &.d-176 {
        animation: dots-green 3s infinite;
      }

      &.d-184 {
        animation: dots-blue 3s infinite;
        animation-delay: 1s;
      }

      &.d-25 {
        animation: dots-green 3s infinite;
        animation-delay: 0.8s;
      }

      &.d-148 {
        animation: dots-red 3s infinite;
        animation-delay: 0.4s;
      }

      &.d-49 {
        animation: dots-red 3s infinite;
        animation-delay: 1s;
      }

      &.d-113 {
        animation: dots-green 3s infinite;
        animation-delay: 1.3s;
      }

      &.d-38 {
        animation: dots-blue 3s infinite;
        animation-delay: 1s;
      }
    }
  }
}

@keyframes dots-green {

  0% {
    fill: $color-primary-10;
  }

  50% {
    fill: $color-primary-300;
  }

  100% {
    fill: $color-primary-10;
  }
}

@keyframes dots-red {

  0% {
    fill: $color-primary-10;
  }

  50% {
    fill: $color-secondary-red-200;
  }

  100% {
    fill: $color-primary-10;
  }
}

@keyframes dots-blue {

  0% {
    fill: $color-primary-10;
  }

  50% {
    fill: $color-secondary-blue-350;
  }

  100% {
    fill: $color-primary-10;
  }
}

a {

  .b-cardButton {
    color: $color-achromatic-black;
  }
}
@media screen and (min-width: 576px) and (max-width: 919px) {
  .payment__cont {
    align-items: center;
    flex-direction: column;
  }

}
@media screen and (min-width: 320px) and (max-width: 575px) {

  h2 {
    font-size: 26px;
  }
  .payment__wrap {
    flex-direction: column;
    gap: 0;
  }
  .payment__cont {
    align-items: center;
    flex-direction: column;
  }
  .payment__card {
    padding: 30px 50px 30px 50px;
  }
  .p-card__txt-wrap {
    margin-bottom: 20px;
  }
  span.p-card__text {
    line-height: 90%;
    font-size: 25px;
  }
}

.award {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  background-color: white;
  min-width: 60px;
  font-size: 0.5em;

  overflow: hidden;
  border-radius: 50%;

  @media screen and (min-width: 620px) {
    min-width: 100px;
    font-size: 0.8em;
  }

  &__icon-text {
    font-size: 2.5em;
  }

  &:before {
    content: "";
    padding-top: 100%;
    float: left;
  }
}

.form-control__select {
  font-size: 16px;
  font-weight: 400;
  font-family: 'Montserrat';
  padding-bottom: 14px;
  border-bottom: 1px solid #949494;
}

.form-control__select:focus {
  padding-bottom: 13px;
  border-bottom: 2px solid #6a87e1;
}



.form-control__input--hidden {
  display: none;
}